import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AccordionComponent,
  AccordionFooterDirective,
  AccordionTitleDirective,
  ButtonComponent,
  DetectFormChangesDirective,
} from '@dougs/ds';
import { EcommerceSalesChannelFormService } from '../../../../forms/ecommerce-sales-channel-form.service';
import { SalesChannelListService } from '../../../../services/sales-channel-list.service';
import { EcommerceSalesChannelFormComponent } from '../ecommerce-sales-channel-form';

@Component({
  selector: 'dougs-ecommerce-sales-channel-add',
  templateUrl: './ecommerce-sales-channel-add.component.html',
  styleUrls: ['./ecommerce-sales-channel-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    EcommerceSalesChannelFormComponent,
    DetectFormChangesDirective,
    ButtonComponent,
    AsyncPipe,
    AccordionComponent,
    AccordionTitleDirective,
    AccordionFooterDirective,
  ],
  providers: [EcommerceSalesChannelFormService],
})
export class EcommerceSalesChannelAddComponent {
  constructor(
    public readonly salesChannelListService: SalesChannelListService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
  ) {}
}
