import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { EcommerceWebsiteConfiguration, MarketPlaceConfiguration, SalesChannelCategory } from '@dougs/ecommerce/dto';
import { SalesChannelStateService } from '@dougs/ecommerce/shared';
import { EcommerceSalesChannelFormService } from '../../../../../forms/ecommerce-sales-channel-form.service';

@Component({
  selector: 'dougs-ecommerce-sales-channel-form-information',
  templateUrl: './ecommerce-sales-channel-form-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    PanelInfoComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    ErrorFormFieldDirective,
    AsyncPipe,
  ],
})
export class EcommerceSalesChannelFormInformationComponent {
  protected readonly SalesChannelCategory = SalesChannelCategory;

  @Input()
  creating!: boolean;

  constructor(
    public formService: FormService,
    public salesChannelStateService: SalesChannelStateService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
  ) {}

  trackByValue(index: number, configuration: MarketPlaceConfiguration | EcommerceWebsiteConfiguration): string {
    return configuration.value;
  }
}
