<div
  id="salesViaPlatformConfigurationlocked-company"
  class="ecommerce-settings-form"
  [formGroup]="ecommerceFormService.formGroup"
>
  <ng-container *ngIf="ecommerceFormService.salesViaPlatformValuesChanges$ | async"></ng-container>
  <div class="form-layout grid mb-8" formGroupName="salesViaPlatformConfiguration">
    <label dougsFormFieldLabel class="question">
      Faites-vous de la vente en ligne (Amazon, Uber, Airbnb, Malt, etc.)&nbsp;?
      <div class="hint flex">
        <i class="fal fa-info-circle mr-8"></i>
        <span>
          Répondez oui si vous vendez via votre site e-commerce, une marketplace, un store d'application mobiles ou si
          vous utilisez un moyen d'encaissement en ligne.
        </span>
      </div>
    </label>
    <dougs-radio-group formControlName="salesViaPlatform" appearance="yesNo">
      <dougs-radio [value]="true">Oui</dougs-radio>
      <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
    </dougs-radio-group>
  </div>
  <div
    *ngIf="ecommerceFormService.formGroup.get('salesViaPlatformConfiguration')?.get('salesViaPlatform')?.value"
    class="mb-16"
    @slideUpDown
  >
    <dougs-panel-info class="p-16">
      <div class="grid mb-4">
        <p class="bold">Extension e-commerce (obligatoire)</p>
        <p class="bold">+10€ HT/mois</p>
      </div>
      <ul class="pl-24 my-0">
        <li>Catégorisation simplifiée de vos ventes en ligne</li>
        <li>Suivi des canaux de vente</li>
        <li>Équipe de spécialistes dédiés e-commerce</li>
        <li>Préparation de vos déclarations fiscales obligatoires (ex: OSS/IOSS)</li>
      </ul>
    </dougs-panel-info>
    <div class="mt-8 mb-16 flex">
      <i class="fal fa-info-circle mr-8"> </i>
      <p class="tiny">
        Pour les plateformes de mise en relation de freelance (ex : Malt), de transport de personnes (ex : Uber) ou de
        location immobilière entre particuliers (ex : Airbnb), veuillez vous
        <a class="bold" (click)="entrySurveyService.openIntercom($event)" href="#">rapprocher d'un conseiller</a>.
      </p>
    </div>
    <dougs-divider class="mb-8"></dougs-divider>
  </div>
  <div class="form-layout mb-8" *ngIf="company.salesViaPlatformConfiguration.salesViaPlatform && !shouldDisplaySurvey">
    <div>
      <dougs-divider class="mb-8"></dougs-divider>
      <p (click)="shouldDisplaySurvey = true" class="tiny display-survey">
        Afficher tout mon questionnaire
        <i class="fal fa-chevron-down"></i>
      </p>
    </div>
  </div>
  <div *ngIf="shouldDisplaySurvey">
    <div *ngIf="company.salesViaPlatformConfiguration.salesViaPlatform" @slideUpDown>
      <dougs-ecommerce-sales-via-platform></dougs-ecommerce-sales-via-platform>
    </div>
    <dougs-panel-info
      class="mb-16"
      type="error"
      *ngIf="
        ecommerceFormService.salesViaPlatformConfigurationGroup.errors?.['individualSalesError'] as salesPlatformError
      "
    >
      {{ salesPlatformError }}
    </dougs-panel-info>

    <div
      *ngIf="
        company.salesViaPlatformConfiguration.salesViaPlatform &&
        company.salesViaPlatformConfiguration.individualEuropeanSales
      "
      @slideUpDown
    >
      <div class="form-layout mb-16">
        <dougs-divider></dougs-divider>
      </div>
      <h5 class="mb-16">Dropshipping</h5>
      <div class="flex mb-16">
        <i class="fal fa-info-circle mr-8"></i>
        <p class="tiny">
          Le dropshipping est une vente à distance à des particuliers de biens importés depuis un pays hors de l'Union
          Européenne directement, sans gestion de stock par le vendeur.
        </p>
      </div>
      <dougs-ecommerce-drop-shipping></dougs-ecommerce-drop-shipping>
      <h5 class="mb-16">Ventes à distance à des particuliers en Union Européenne</h5>
      <dougs-ecommerce-individual-sales></dougs-ecommerce-individual-sales>
      <dougs-ecommerce-individual-services></dougs-ecommerce-individual-services>
    </div>

    <div *ngIf="company.salesViaPlatformConfiguration.shouldShowEuIndividualRevenueOver10000">
      <dougs-divider class="mb-16"></dougs-divider>
      <h5 class="mb-16">Seuil de ventes en Union Européenne</h5>
      <dougs-ecommerce-european-individual-revenue></dougs-ecommerce-european-individual-revenue>
      <dougs-ecommerce-force-oss
        *ngIf="company.salesViaPlatformConfiguration.shouldShowForceOssWithOption"
      ></dougs-ecommerce-force-oss>
    </div>

    <div
      *ngIf="
        (company.accountingConfiguration.hasOss || company.accountingConfiguration.hasIoss) &&
        company.salesViaPlatformConfiguration.locked
      "
    ></div>
  </div>
</div>
