import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SalesChannel } from '@dougs/ecommerce/dto';

@Injectable()
export class SalesChannelListService {
  private editedSalesChannelId?: number;
  private readonly _editedChannelIdChange = new BehaviorSubject<number | undefined>(undefined);
  private readonly isCreatingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  shouldDisplayInactiveSalesChannel = false;
  editedChannelIdChange$ = this._editedChannelIdChange.asObservable();
  isCreating$: Observable<boolean> = this.isCreatingSubject.asObservable();

  set isCreating(value: boolean) {
    this.isCreatingSubject.next(value);
  }

  setEditedSalesChannelId(salesChannel?: SalesChannel): void {
    if (this.editedSalesChannelId !== salesChannel?.id) {
      if (salesChannel) {
        this.isCreating = false;
      }
      this.editedSalesChannelId = salesChannel?.id;
      this._editedChannelIdChange.next(salesChannel?.id);
    }
  }

  abortEdition(event: Event): void {
    event.stopPropagation();
    this.setEditedSalesChannelId();
    this.isCreating = false;
  }
}
