import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormArray } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  DividerComponent,
  ErrorFormFieldDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  ModalService,
  Option,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { PaymentsProcessor } from '@dougs/ecommerce/dto';
import { PaymentsProcessorStateService } from '@dougs/ecommerce/shared';
import { EcommerceSalesChannelFormService } from '../../../../../forms/ecommerce-sales-channel-form.service';
import { CreatePaymentsProcessorComponent } from '../../../../../modals/create-payments-processor-modal/create-payments-processor-modal.component';

@Component({
  selector: 'dougs-ecommerce-sales-channel-form-processors',
  templateUrl: './ecommerce-sales-channel-form-processors.component.html',
  styleUrls: ['./ecommerce-sales-channel-form-processors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DividerComponent,
    FormsModule,
    ReactiveFormsModule,
    PanelInfoComponent,
    NgIf,
    NgFor,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    SelectOptionComponent,
    ErrorFormFieldDirective,
    CheckboxComponent,
    InputDatepickerComponent,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class EcommerceSalesChannelFormProcessorsComponent {
  @Output() deletePaymentsProcessorWithDate: EventEmitter<number> = new EventEmitter<number>();
  @Output() addPaymentsProcessorWithDate: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public paymentsProcessorStateService: PaymentsProcessorStateService,
    public formService: FormService,
    private readonly modalService: ModalService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
  ) {}

  onNotUsedAnymoreChange(notUsedAnymore: boolean, index: number) {
    if (!notUsedAnymore) {
      this.ecommerceSalesChannelFormService.getEndDateControlAtIndex(index)?.setValue(null);
      this.ecommerceSalesChannelFormService.getEndDateControlAtIndex(index)?.disable();
    } else {
      this.ecommerceSalesChannelFormService.getEndDateControlAtIndex(index)?.enable();
    }
  }

  trackById(index: number, paymentsProcessor: PaymentsProcessor): number {
    return paymentsProcessor.id;
  }

  trackByIndex(index: number): number {
    return index;
  }

  async paymentProcessorSelected(option: Option): Promise<void> {
    if (option.value === -1) {
      await this.openPaymentProcessorModal();
    }
  }

  async openPaymentProcessorModal(): Promise<void> {
    const { data } = await lastValueFrom(this.modalService.open(CreatePaymentsProcessorComponent).afterClosed$);
    const paymentProcessorControl: UntypedFormArray =
      this.ecommerceSalesChannelFormService.formGroup.controls.paymentsProcessorsWithDate;
    paymentProcessorControl
      .at(paymentProcessorControl.length - 1)
      ?.get('paymentsProcessorId')
      ?.setValue(data);
  }
}
