import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { EcommerceShopSyncModalComponentData, EcommerceShopSyncMode } from '@dougs/ecommerce/dto';
import { EcommerceShopUrlComponent } from '../../components/ecommerce-sales-channels/ecommerce-sales-channel/ecommerce-sales-channel-form/ecommerce-shop-sync-form/ecommerce-shop-url/ecommerce-shop-url.component';
import { EcommerceShopSyncModalComponentService } from '../../services/ecommerce-shop-sync-modal.component.service';

@Component({
  selector: 'dougs-ecommerce-shop-sync-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    FormsModule,
    ReactiveFormsModule,
    ErrorFormFieldDirective,
    EcommerceShopUrlComponent,
    ModalCloseDirective,
    PanelInfoComponent,
  ],
  providers: [EcommerceShopSyncModalComponentService],
  templateUrl: './ecommerce-shop-sync-modal.component.html',
  styleUrls: ['./ecommerce-shop-sync-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcommerceShopSyncModalComponent {
  protected readonly SyncMode = EcommerceShopSyncMode;

  constructor(
    public readonly ecommerceShopSyncModalComponentService: EcommerceShopSyncModalComponentService,
    @Inject(MODAL_DATA) public data: EcommerceShopSyncModalComponentData,
  ) {}
}
