import { AbstractControl, ValidatorFn } from '@angular/forms';

export const shouldSellSomething = (): ValidatorFn => {
  return (formGroup: AbstractControl) => {
    if (
      formGroup.get('sellsMerchandise')?.value === false &&
      formGroup.get('sellsFinishedProduct')?.value === false &&
      formGroup.get('salesServices')?.value === false
    ) {
      return { shouldSellSomething: true };
    }
    return null;
  };
};
