import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { FormService } from '@dougs/core/form';
import { MetricsService } from '@dougs/core/metrics';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  HelpModalComponent,
  LabelFormFieldDirective,
  LoaderFullpageComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  Option,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { PaymentsProcessor } from '@dougs/ecommerce/dto';
import { PaymentsProcessorStateService } from '@dougs/ecommerce/shared';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-create-payments-processor-modal',
  templateUrl: './create-payments-processor-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    LabelFormFieldDirective,
    FormFieldComponent,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    ErrorFormFieldDirective,
    PanelInfoComponent,
    ModalFooterDirective,
    ButtonComponent,
    LoaderFullpageComponent,
    AsyncPipe,
  ],
})
export class CreatePaymentsProcessorComponent implements OnInit, OnDestroy {
  formGroup!: UntypedFormGroup;
  isLoading = false;
  onTypeChange: Subscription | undefined;

  mustSynchronize$!: Observable<boolean>;
  synchronizableAccounts$!: Observable<SynchronizedAccount[]>;

  private readonly defaultMemo: string = 'Ex. : mon compte EUR';
  memoPlaceholder: string = this.defaultMemo;

  get type(): AbstractControl | null {
    return this.formGroup.get('type');
  }

  get memo(): AbstractControl | null {
    return this.formGroup.get('memo');
  }

  get synchronizedAccountId(): AbstractControl | null {
    return this.formGroup.get('synchronizedAccountId');
  }

  constructor(
    private readonly companyStateService: CompanyStateService,
    public formService: FormService,
    private readonly cdr: ChangeDetectorRef,
    public readonly paymentsProcessorStateService: PaymentsProcessorStateService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly modalRef: ModalRef,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.paymentsProcessorStateService.refreshPaymentsProcessorsConfiguration();
    await this.synchronizedAccountStateService.refreshSynchronizedAccounts(this.companyStateService.activeCompany.id);
    this.buildForm();
    this.setMustSynchronized();
    await this.setSynchronizableAccounts();
    this.onTypeChange = this.subscribeOnTypeChange();
  }

  ngOnDestroy(): void {
    this.onTypeChange?.unsubscribe();
  }

  private buildForm(): void {
    this.formGroup = new UntypedFormGroup({
      type: new UntypedFormControl(undefined, Validators.required),
      memo: new UntypedFormControl(undefined, Validators.required),
      synchronizedAccountId: new UntypedFormControl(undefined),
    });
    this.cdr.markForCheck();
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.isLoading = true;
      const createdPaymentsProcessor = await this.savePaymentsProcessor();
      if (createdPaymentsProcessor) {
        this.modalRef.close(createdPaymentsProcessor.id);
      }
      this.isLoading = false;
      this.metricsService.pushMixpanelEvent('e-Commerce Payments processor created', {
        'CTA Location': 'Settings Module',
      });
    }
  }

  private async savePaymentsProcessor(): Promise<PaymentsProcessor | undefined> {
    return await this.paymentsProcessorStateService.createPaymentsProcessor(
      this.companyStateService.activeCompany,
      this.formGroup.value,
    );
  }

  private setMustSynchronized(): void {
    if (this.type) {
      this.mustSynchronize$ = combineLatest([
        this.type.valueChanges,
        this.paymentsProcessorStateService.paymentsProcessorsConfigurationList$,
      ]).pipe(
        map(([type, configs]) => {
          return !!configs.find((c) => c.value === type)?.mustSynchronize;
        }),
      );
    }
  }

  private async setSynchronizableAccounts(): Promise<void> {
    this.synchronizableAccounts$ = combineLatest([
      this.synchronizedAccountStateService.synchronizedAccounts$,
      this.paymentsProcessorStateService.paymentsProcessorsConfigurationList$,
    ]).pipe(
      map(([synchronizedAccounts, configs]) => {
        return synchronizedAccounts.filter((synchronizedAccount) => {
          const config = configs.find((config) => config.label === synchronizedAccount.bankName);
          return config?.mustSynchronize;
        });
      }),
    );
  }

  private subscribeOnTypeChange(): Subscription | undefined {
    return this.type?.valueChanges.subscribe(() => {
      this.formGroup.get('synchronizedAccountId')?.setValue(null);
      this.cdr.markForCheck();
    });
  }

  trackById(index: number, synchronizedAccount: SynchronizedAccount): number {
    return synchronizedAccount.id;
  }

  serviceSelected(option: Option): void {
    this.memoPlaceholder = `${this.defaultMemo} ${option.value}`;
  }

  showAccountNameHelpModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'Nom de votre compte de ce service',
        body: 'Le nom que vous voulez voir dans Dougs.',
      },
    });
  }

  showServiceTypeHelpModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: "Service d'encaissement utilisé",
        body: "Le service que vous utilisez n'est pas dans la liste ?",
        contactUsMessage: 'Contactez-nous',
      },
    });
  }
}
