import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'salesChannelIcon', standalone: true })
export class SalesChannelIconPipe implements PipeTransform {
  transform(salesChannelName: string): string | undefined {
    if (!salesChannelName) {
      return undefined;
    }
    const existingSalesChannelIcons: string[] = [
      'amazon',
      'cdiscount',
      'ebay',
      'prestashop',
      'shopify',
      'wooCommerce',
      'etsy',
    ];
    const icon: string | undefined = existingSalesChannelIcons.find((icon) => salesChannelName.includes(icon));

    if (icon) {
      return `images/marketplaces/${icon}.svg`;
    }

    return undefined;
  }
}
