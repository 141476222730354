import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { FileInputComponent, FilePillComponent, InputDatepickerComponent, LabelFormFieldDirective } from '@dougs/ds';
import { EcommerceFormService } from '../../../forms/ecommerce-form.service';

@Component({
  selector: 'dougs-ecommerce-inscription',
  templateUrl: './ecommerce-inscription.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InputDatepickerComponent,
    LabelFormFieldDirective,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    AsyncPipe,
  ],
})
export class EcommerceInscriptionComponent {
  @Input() company!: Company;

  constructor(public ecommerceFormService: EcommerceFormService) {}

  trackByAttachmentId(index: number, item: Attachment): number {
    return +item.id;
  }
}
