import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import {
  DividerComponent,
  LabelFormFieldDirective,
  PanelInfoComponent,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { EcommerceFormService } from '../../forms/ecommerce-form.service';
import { EcommerceDropShippingComponent } from './ecommerce-drop-shipping/ecommerce-drop-shipping.component';
import { EcommerceEuropeanIndividualRevenueComponent } from './ecommerce-european-individual-revenue/ecommerce-european-individual-revenue.component';
import { EcommerceForceOssComponent } from './ecommerce-force-oss/ecommerce-force-oss.component';
import { EcommerceIndividualSalesComponent } from './ecommerce-individual-sales/ecommerce-individual-sales.component';
import { EcommerceIndividualServicesComponent } from './ecommerce-individual-services/ecommerce-individual-services.component';
import { EcommerceSalesViaPlatformComponent } from './ecommerce-sales-via-platform/ecommerce-sales-via-platform.component';

@Component({
  selector: 'dougs-ecommerce-form',
  templateUrl: './ecommerce-form.component.html',
  styleUrls: ['./ecommerce-form.component.scss'],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({ height: 0, opacity: 0, overflow: 'hidden', display: 'block', marginBottom: 0 }),
        animate('300ms ease-in-out', style({ height: '*', opacity: 1, marginBottom: '16px' })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1, overflow: 'hidden', display: 'block', paddingBottom: '16px' }),
        animate('300ms ease-in-out', style({ height: 0, opacity: 0, paddingBottom: 0 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    LabelFormFieldDirective,
    RadioGroupComponent,
    RadioComponent,
    DividerComponent,
    EcommerceSalesViaPlatformComponent,
    PanelInfoComponent,
    EcommerceDropShippingComponent,
    EcommerceIndividualSalesComponent,
    EcommerceIndividualServicesComponent,
    EcommerceEuropeanIndividualRevenueComponent,
    EcommerceForceOssComponent,
    AsyncPipe,
  ],
})
export class EcommerceFormComponent {
  shouldDisplaySurvey = false;
  private _company!: Company;

  @Input()
  set company(company: Company) {
    this._company = company;

    this.shouldDisplaySurvey =
      !company.salesViaPlatformConfiguration.locked || !company.salesViaPlatformConfiguration.dateLocked;
  }

  get company() {
    return this._company;
  }

  constructor(public ecommerceFormService: EcommerceFormService) {}
}
