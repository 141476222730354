<dougs-divider class="mt-16"></dougs-divider>
<div [formGroup]="ecommerceSalesChannelFormService.formGroup">
  <h5 class="my-16">Services d'encaissement</h5>
  <ng-container formArrayName="paymentsProcessorsWithDate">
    <dougs-panel-info
      type="error"
      *ngIf="
        formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.get('paymentsProcessorsWithDate')) &&
        ecommerceSalesChannelFormService.formGroup
          .get('paymentsProcessorsWithDate')
          ?.hasError('shouldHaveOnePaymentProcessor')
      "
      class="mb-8"
    >
      Vous devez ajouter au moins un service de paiement.
    </dougs-panel-info>
    <ng-container
      *ngFor="
        let control of ecommerceSalesChannelFormService.formGroup.controls.paymentsProcessorsWithDate.controls;
        index as i;
        last as isLast;
        trackBy: trackByIndex
      "
    >
      <div class="payment-processor-item my-16">
        <div class="payment-processor-item-icon mr-8"><i class="fal fa-landmark"></i></div>
        <ng-container [formGroupName]="i">
          <div class="payment-processor-item-form">
            <div class="form-field-line-payment-processor mb-16">
              <dougs-form-field [noMargin]="true">
                <label dougsFormFieldLabel>Vous encaissez avec</label>
                <dougs-select
                  dougsFormFieldControl
                  formControlName="paymentsProcessorId"
                  placeholder="Sélectionnez un service d'encaissement"
                  (select)="paymentProcessorSelected($event)"
                >
                  <dougs-select-option
                    *ngFor="
                      let paymentsProcessor of paymentsProcessorStateService.paymentsProcessors$ | async;
                      trackBy: trackById
                    "
                    [value]="paymentsProcessor.id"
                  >
                    {{ paymentsProcessor.name }}
                  </dougs-select-option>
                  <dougs-select-option [value]="-1" label="Ajouter un service">
                    <b>+ Ajouter un service</b>
                  </dougs-select-option>
                </dougs-select>
                <span
                  *ngIf="
                    formService.isControlInvalid(
                      ecommerceSalesChannelFormService.getPaymentsProcessorIdControlAtIndex(i)
                    )
                  "
                  dougsFormFieldError
                >
                  <span
                    *ngIf="
                      ecommerceSalesChannelFormService.getPaymentsProcessorIdControlAtIndex(i).hasError('required')
                    "
                  >
                    Ce champ est requis
                  </span>
                </span>
              </dougs-form-field>
              <i (click)="deletePaymentsProcessorWithDate.emit(i)" class="fal fa-times pointer mt-16"></i>
            </div>

            <dougs-checkbox
              label="Vous arrêtez d'utiliser ce service ?"
              class="mt-16"
              appearance="toggle"
              [showYesNo]="true"
              (click)="$event.stopPropagation()"
              [ngModel]="!!ecommerceSalesChannelFormService.getEndDateControlAtIndex(i).value"
              (ngModelChange)="onNotUsedAnymoreChange($event, i)"
              [ngModelOptions]="{ standalone: true }"
            ></dougs-checkbox>
            <dougs-input-datepicker
              label="Fin d'utilisation depuis le :"
              class="mt-16 pr-24"
              formControlName="endDate"
              *ngIf="ecommerceSalesChannelFormService.getEndDateControlAtIndex(i).enabled"
            ></dougs-input-datepicker>
          </div>
        </ng-container>
      </div>
      <dougs-divider *ngIf="!isLast" class="mb-8"></dougs-divider>
    </ng-container>
  </ng-container>
  <div class="form-field-line">
    <dougs-button (click)="addPaymentsProcessorWithDate.emit()" class="full-width" color="primary-light medium">
      <i class="mr-8 fas color-primary fa-plus-circle"></i>
      Ajouter un service d'encaissement
    </dougs-button>
  </div>
</div>
