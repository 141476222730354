<div class="settings-card__content">
  <div>
    <div class="settings-card__sticky-column-title">
      <h5 class="mb-8">Canaux de vente</h5>
      <p class="subtitle my-8">
        Réalisez votre comptabilité par canal de vente pour avoir une meilleure visibilité sur vos chiffres.
      </p>
      <dougs-checkbox
        [(ngModel)]="salesChannelListService.shouldDisplayInactiveSalesChannel"
        appearance="toggle"
        [showYesNo]="true"
        yesText="Masquer les canaux de vente fermés"
        noText="Afficher les canaux de vente fermés"
      ></dougs-checkbox>
    </div>
  </div>
  <div id="sales-channel-content">
    <dougs-sales-channel-item
      *ngFor="
        let salesChannel of salesChannelStateService.activeSalesChannels | async;
        let isFirst = first;
        trackBy: trackById
      "
      [editedSalesChannelId]="salesChannelListService.editedChannelIdChange$ | async"
      [salesChannel]="salesChannel"
      [class.mt-16]="!isFirst"
    ></dougs-sales-channel-item>
    <ng-container *ngIf="salesChannelListService.shouldDisplayInactiveSalesChannel">
      <dougs-sales-channel-item
        *ngFor="
          let salesChannel of salesChannelStateService.inactiveSalesChannels | async;
          let isFirst = first;
          trackBy: trackById
        "
        [editedSalesChannelId]="salesChannelListService.editedChannelIdChange$ | async"
        [salesChannel]="salesChannel"
        class="mt-16"
      ></dougs-sales-channel-item>
    </ng-container>
    <div
      class="form-layout"
      *ngIf="
        (salesChannelStateService.salesChannels$ | async)?.length === 0 &&
        !(salesChannelListService.isCreating$ | async)
      "
    >
      <dougs-blank-state maxWidth="200" [autoWidth]="true" illustration="sales-channel-blank-slate">
        <p class="mt-16">Vous n'avez pas de canal de vente</p>
      </dougs-blank-state>
    </div>
    <dougs-ecommerce-sales-channel-add
      *ngIf="salesChannelListService.isCreating$ | async"
      class="mt-16"
      @slideInOut
    ></dougs-ecommerce-sales-channel-add>
    <dougs-button
      *ngIf="!(salesChannelListService.isCreating$ | async)"
      class="mt-16"
      color="primary-light"
      [fullWidth]="true"
      (click)="addNewSalesChannel()"
      @slideInOut
    >
      <i class="mr-8 color-primary fas fa-plus-circle"></i>
      Ajouter un canal de vente
    </dougs-button>
  </div>
</div>
