<ng-container [formGroup]="ecommerceSalesChannelFormService.formGroup">
  <h5 class="my-16">Type de ventes</h5>
  <dougs-panel-info
    type="error"
    *ngIf="ecommerceSalesChannelFormService.formGroup.errors?.shouldSellSomething"
    class="mb-8"
  >
    Vous devez répondre oui à au moins une question
  </dougs-panel-info>
  <dougs-panel-info
    type="error"
    *ngIf="ecommerceSalesChannelFormService.formGroup.errors?.shouldAnswerSellsQuestions"
    class="mb-8"
  >
    Vous devez répondre à toutes les questions
  </dougs-panel-info>
  <div class="question-list mb-16">
    <div class="question-list-icon">
      <i class="fal fa-store"></i>
    </div>
    <div class="question-list-values">
      <h6 class="mb-16">À travers ce canal de vente, vous vendez :</h6>
      <div class="line mb-8">
        <div>
          <p class="small">
            de la marchandise
            <i class="fal fa-question-circle help" (click)="showMerchandiseHelpModal()"></i>
          </p>
          <dougs-ecommerce-sales-channel-required-control
            *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.sellsMerchandise)"
          ></dougs-ecommerce-sales-channel-required-control>
        </div>
        <dougs-radio-group id="sellsMerchandise" formControlName="sellsMerchandise" appearance="yesNo">
          <dougs-radio [value]="true" class="mr-8">Oui</dougs-radio>
          <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
        </dougs-radio-group>
      </div>
      <div class="line mb-8">
        <div>
          <p class="small">
            des produits finis
            <i class="fal fa-question-circle help" (click)="showFinishedProductHelpModal()"></i>
          </p>
          <dougs-ecommerce-sales-channel-required-control
            *ngIf="
              formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.sellsFinishedProduct)
            "
          ></dougs-ecommerce-sales-channel-required-control>
        </div>
        <dougs-radio-group id="sellsFinishedProduct" formControlName="sellsFinishedProduct" appearance="yesNo">
          <dougs-radio [value]="true" class="mr-8">Oui</dougs-radio>
          <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
        </dougs-radio-group>
      </div>
      <div class="line mb-16">
        <div>
          <p class="small">
            du service
            <i class="fal fa-question-circle help" (click)="showServiceHelpModal()"></i>
          </p>
          <dougs-ecommerce-sales-channel-required-control
            *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.salesServices)"
          ></dougs-ecommerce-sales-channel-required-control>
        </div>
        <dougs-radio-group id="salesServices" formControlName="salesServices" appearance="yesNo">
          <dougs-radio [value]="true" class="mr-8">Oui</dougs-radio>
          <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
        </dougs-radio-group>
      </div>
    </div>
  </div>
  <div
    class="question-list"
    *ngIf="
      ecommerceSalesChannelFormService.formGroup.controls.sellsMerchandise.value === true ||
      ecommerceSalesChannelFormService.formGroup.controls.sellsFinishedProduct.value === true
    "
  >
    <div class="question-list-icon">
      <i class="fal fa-map-marker-alt"></i>
    </div>
    <div class="question-list-values">
      <h6 class="mb-16">Vous expédiez vos ventes à vos clients depuis :</h6>
      <dougs-panel-info
        type="error"
        *ngIf="ecommerceSalesChannelFormService.formGroup.errors?.shouldHaveOneStock"
        class="mb-8"
      >
        Vous devez répondre oui à au moins une question
      </dougs-panel-info>
      <div class="line mb-8">
        <div>
          <p class="small">du stock en France</p>
          <dougs-ecommerce-sales-channel-required-control
            *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.fromFrance)"
          ></dougs-ecommerce-sales-channel-required-control>
        </div>
        <dougs-radio-group id="fromFrance" formControlName="fromFrance" appearance="yesNo">
          <dougs-radio [value]="true" class="mr-8">Oui</dougs-radio>
          <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
        </dougs-radio-group>
      </div>
      <div class="line mb-8">
        <div>
          <p class="small">du stock en Union Européenne (hors France)</p>
          <dougs-ecommerce-sales-channel-required-control
            *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.fromEu)"
          ></dougs-ecommerce-sales-channel-required-control>
        </div>
        <dougs-radio-group id="fromEu" formControlName="fromEu" appearance="yesNo">
          <dougs-radio [value]="true" class="mr-8">Oui</dougs-radio>
          <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
        </dougs-radio-group>
      </div>
      <div class="line mb-8">
        <div>
          <p class="small">du stock hors Union Européenne</p>
          <dougs-ecommerce-sales-channel-required-control
            *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.fromOutsideEu)"
          ></dougs-ecommerce-sales-channel-required-control>
        </div>
        <dougs-radio-group id="fromOutsideEu" formControlName="fromOutsideEu" appearance="yesNo">
          <dougs-radio [value]="true" class="mr-8">Oui</dougs-radio>
          <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
        </dougs-radio-group>
      </div>
    </div>
  </div>
</ng-container>
