<div dougsModalTitle>
  <h6>Nouveau canal de vente</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<form
  (ngSubmit)="onSubmit()"
  [formGroup]="ecommerceSalesChannelFormService.formGroup"
  *ngIf="ecommerceSalesChannelFormService.formGroup && !ecommerceSalesChannelFormService.isLoading$()"
>
  <div dougsModalContent>
    <dougs-ecommerce-sales-channel-form
      [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
    ></dougs-ecommerce-sales-channel-form>
  </div>

  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Ajouter</dougs-button>
  </div>
</form>
