import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent, DetectFormChangesDirective, DividerComponent } from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { UserStateService } from '@dougs/user/shared';
import { EcommerceSalesChannelFormService } from '../../../forms/ecommerce-sales-channel-form.service';
import { EcommerceSalesChannelItemComponentService } from '../../../services/ecommerce-sales-channel-item.component.service';
import { SalesChannelListService } from '../../../services/sales-channel-list.service';
import { EcommerceSalesChannelFormComponent } from './ecommerce-sales-channel-form';

@Component({
  selector: 'dougs-ecommerce-sales-channel',
  templateUrl: './ecommerce-sales-channel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    EcommerceSalesChannelFormComponent,
    DetectFormChangesDirective,
    DividerComponent,
    ButtonComponent,
    AsyncPipe,
    NgIf,
  ],
})
export class EcommerceSalesChannelComponent {
  @Input()
  salesChannel: SalesChannel | undefined;

  constructor(
    public readonly ecommerceSalesChannelItemComponentService: EcommerceSalesChannelItemComponentService,
    public readonly formEcommerceSalesChannelService: EcommerceSalesChannelFormService,
    public readonly salesChannelListService: SalesChannelListService,
    public readonly userStateService: UserStateService,
  ) {}
}
