<ng-container [formGroup]="ecommerceSalesChannelFormService.formGroup">
  <dougs-ecommerce-shop-url
    [shopType]="ecommerceSalesChannelFormService.formGroup.controls.type.value"
    [isTouched]="ecommerceSalesChannelFormService.formGroup.controls.shopUrl.touched"
    [noMargin]="true"
    formControlName="shopUrl"
  />
</ng-container>

@if (ecommerceSalesChannelFormService.isShopSynchronized$ | async) {
  <dougs-pill type="success" class="mt-8 mr-4">
    <i class="fal fa-check-circle mr-4"></i>
    Boutique synchronisée
  </dougs-pill>
} @else {
  <dougs-pill type="warning" class="mt-8 mr-4">
    <i class="fal fa-circle-xmark mr-4"></i>
    Boutique non synchronisée
  </dougs-pill>
}
<div class="sync mt-16">
  <dougs-checkbox
    [formControl]="ecommerceSalesChannelFormService.formGroup.controls.synchronizeShop"
    [showYesNo]="true"
    [label]="ecommerceShopSyncFormComponentService.checkboxLabel$()"
    appearance="toggle"
  />
  <i
    (click)="ecommerceShopSyncFormComponentService.openSyncInfoModal()"
    class="fal fa-question-circle pointer mr-4"
  ></i>
</div>
<div class="sync mt-16">
  <dougs-ecommerce-beta-pill />
  <p class="tiny ml-8">Cette fonctionnalité est encore en version bêta, nous corrigeons les derniers détails.</p>
</div>
