<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.platformCommissionValuesChanges$ | async"></ng-container>
  <ng-container *ngIf="ecommerceFormService.individualEuropeanSalesValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <h5 class="mb-16">Dites-nous-en plus sur vos ventes en ligne</h5>
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        La plateforme numérique vous prélève-t-elle des commissions ou des frais avant de vous reverser le montant des
        ventes&nbsp;?
      </label>
      <dougs-radio-group formControlName="platformCommission" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous de la vente à des particuliers en Union Européenne&nbsp;?
        <div class="hint flex">
          <i class="fal fa-info-circle mr-8"></i>
          <span>Répondez "Non" si vous vendez uniquement des applications via l'AppStore ou le PlayStore.</span>
        </div>
      </label>
      <dougs-radio-group formControlName="individualEuropeanSales" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
  </ng-container>
</ng-container>
