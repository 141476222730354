import { computed, EventEmitter, Injectable, Signal } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { EcommerceSalesChannelFormService } from '../forms/ecommerce-sales-channel-form.service';

@Injectable()
export class EcommerceShopSyncFormComponentService {
  syncInfoModalOpened: EventEmitter<void> = new EventEmitter<void>();
  checkboxLabel$: Signal<string> = computed(
    () => 'Synchroniser ma boutique ' + this.ecommerceSalesChannelFormService.shopTypeLabel$(),
  );

  constructor(
    private readonly modalService: ModalService,
    private readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
  ) {}

  async openSyncInfoModal(): Promise<void> {
    this.syncInfoModalOpened.emit();
    const { EcommerceShopSyncInfoModalComponent } = await import(
      '../components/modals/ecommerce-shop-sync-info-modal/ecommerce-shop-sync-info-modal.component'
    );
    this.modalService.open(EcommerceShopSyncInfoModalComponent, {
      data: { shopTypeLabel: this.ecommerceSalesChannelFormService.shopTypeLabel$() },
    });
  }
}
