import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  DetectFormChangesDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { PaymentsProcessorStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
import { EcommerceSalesChannelFormComponent } from '../../components/ecommerce-sales-channels/ecommerce-sales-channel/ecommerce-sales-channel-form/ecommerce-sales-channel-form.component';
import { EcommerceSalesChannelFormService } from '../../forms/ecommerce-sales-channel-form.service';
import { SalesChannelListService } from '../../services/sales-channel-list.service';

@Component({
  selector: 'dougs-create-salesChannel-modal',
  templateUrl: './create-sales-channel-modal.component.html',
  styleUrls: ['./create-sales-channel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SalesChannelListService, EcommerceSalesChannelFormService],
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ModalContentDirective,
    EcommerceSalesChannelFormComponent,
    DetectFormChangesDirective,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class CreateSalesChannelModalComponent implements OnInit {
  constructor(
    private readonly companyStateService: CompanyStateService,
    public formService: FormService,
    public readonly paymentsProcessorStateService: PaymentsProcessorStateService,
    public readonly salesChannelStateService: SalesChannelStateService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly salesChannelListService: SalesChannelListService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.salesChannelListService.isCreating = true;
    await this.paymentsProcessorStateService.refreshPaymentsProcessors(this.companyStateService.activeCompany);
    await this.salesChannelStateService.refreshMarketPlacesConfiguration();
    await this.salesChannelStateService.refreshEcommerceWebsiteSoftwareConfiguration();
    this.cdr.markForCheck();
  }

  async onSubmit(): Promise<void> {
    const createdSalesChannel: SalesChannel | null = await this.ecommerceSalesChannelFormService.onSubmit(
      undefined,
      true,
    );
    if (createdSalesChannel) {
      this.modalRef.close(createdSalesChannel.id);
    }
  }
}
