import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DividerComponent, LabelFormFieldDirective, RadioComponent, RadioGroupComponent } from '@dougs/ds';
import { EcommerceFormService } from '../../../forms/ecommerce-form.service';

@Component({
  selector: 'dougs-ecommerce-sales-via-platform',
  templateUrl: './ecommerce-sales-via-platform.component.html',
  styleUrls: ['../ecommerce-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    LabelFormFieldDirective,
    RadioGroupComponent,
    RadioComponent,
    DividerComponent,
    AsyncPipe,
  ],
})
export class EcommerceSalesViaPlatformComponent {
  constructor(public ecommerceFormService: EcommerceFormService) {}
}
