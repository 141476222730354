<ng-container *ngIf="ecommerceShopSyncModalComponentService.metricsBeforeClosed$ | async"></ng-container>

<div dougsModalTitle>
  <h6>Synchronisez votre boutique {{ data.salesChannel.memo }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>

<form
  [formGroup]="ecommerceShopSyncModalComponentService.form"
  (ngSubmit)="ecommerceShopSyncModalComponentService.submit()"
>
  <div dougsModalContent>
    <dougs-panel-info type="info" class="flex mb-16">
      <i class="fal fa-circle-info color-primary mr-8"></i>
      <div>
        <h6>Mode de fonctionnement</h6>
        @if (ecommerceShopSyncModalComponentService.syncMode === SyncMode.URL) {
          <p class="tiny">
            - nous récupérons toutes les commandes présentes dans votre boutique<br />
            - si vous vendez différents types de ventes (marchandises, produits finis, prestations de service), toutes
            vos ventes seront ventilées en tant que "marchandises". Il faudra les corriger.
          </p>
        } @else {
          <p class="tiny">- nous récupérons uniquement les commandes payées qui sont réalisées via cette boutique.</p>
        }
      </div>
    </dougs-panel-info>
    <dougs-ecommerce-shop-url [shopType]="data.salesChannel.type" [noMargin]="true" formControlName="shopUrl" />
    @if (ecommerceShopSyncModalComponentService.syncMode === SyncMode.URL) {
      <dougs-panel-info type="info" class="mt-16">
        <h6>Synchronisation via OAuth</h6>
        <p class="tiny">
          Vous allez être redirigé vers {{ ecommerceShopSyncModalComponentService.shopTypeLabel }} pour autoriser Dougs
          à accéder aux données de votre boutique.
        </p>
      </dougs-panel-info>
    } @else {
      <p class="small bold color-primary-700 mt-16">Connexion via clés d'API</p>
      <p class="small color-primary-700 mt-8">
        Pour synchroniser votre boutique {{ ecommerceShopSyncModalComponentService.shopTypeLabel }}, vous avez besoin de
        renseigner vos clés publique et secrète.
      </p>
      <p class="small color-primary-700 mt-8">
        <a
          href="https://woocommerce.com/document/woocommerce-rest-api/#generate-api-keys"
          target="_blank"
          rel="noopener"
        >
          Comment trouver vos clés d'API {{ ecommerceShopSyncModalComponentService.shopTypeLabel }} ?
          <i class="fal fa-external-link ml-8"></i>
        </a>
      </p>
      @if (ecommerceShopSyncModalComponentService.form.controls.apiKeys; as apiKeys) {
        <ng-container formGroupName="apiKeys">
          <dougs-form-field [autoWidth]="true" [noMargin]="true" class="mt-16">
            <label dougsFormFieldLabel for="publicKey">Clé d'API publique</label>
            <input
              formControlName="publicKey"
              dougsFormFieldControl
              placeholder="Clé d'API publique de votre boutique"
              type="text"
              id="publicKey"
            />
            @if (apiKeys.controls.publicKey.errors && apiKeys.controls.publicKey.touched) {
              <span dougsFormFieldError>
                @if (apiKeys.controls.publicKey.hasError('required')) {
                  Ce champ est requis
                } @else if (
                  apiKeys.controls.publicKey.hasError('minLength') || apiKeys.controls.publicKey.hasError('maxLength')
                ) {
                  La clé d'API publique doit comporter entre 20 et 128 caractères
                }
              </span>
            }
          </dougs-form-field>

          <dougs-form-field [autoWidth]="true" [noMargin]="true" class="mt-16">
            <label dougsFormFieldLabel for="secretKey">Clé d'API secrète</label>
            <input
              formControlName="secretKey"
              dougsFormFieldControl
              placeholder="Clé d'API secrète de votre boutique"
              type="password"
              id="secretKey"
            />
            @if (apiKeys.controls.secretKey.errors && apiKeys.controls.secretKey.touched) {
              <span dougsFormFieldError>
                @if (apiKeys.controls.secretKey.hasError('required')) {
                  <span> Ce champ est requis </span>
                } @else if (apiKeys.controls.secretKey.hasError('minLength')) {
                  <span> La clé d'API secrète doit comporter au moins 20 caractères </span>
                }
              </span>
            }
          </dougs-form-field>
        </ng-container>
      }
    }
  </div>

  <div dougsModalFooter class="mt-16">
    <dougs-button dougsModalClose color="secondary" type="button">Plus tard</dougs-button>
    <dougs-button
      [disabled]="
        ecommerceShopSyncModalComponentService.form.invalid || ecommerceShopSyncModalComponentService.isLoading$()
      "
      color="primary"
      type="submit"
    >
      Synchroniser
    </dougs-button>
  </div>
</form>
