<form class="form-payments-processor" (ngSubmit)="onSubmit()" [formGroup]="formGroup" *ngIf="formGroup">
  <div dougsModalTitle>
    <h6>Ajouter un service d'encaissement</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-form-field>
      <label dougsFormFieldLabel>
        Nom de votre compte de ce service
        <i class="fal fa-question-circle help" (click)="showAccountNameHelpModal()"></i>
      </label>
      <input dougsFormFieldControl type="text" formControlName="memo" [placeholder]="memoPlaceholder" />
      <span *ngIf="formService.isControlInvalid(memo)" dougsFormFieldError>
        <span *ngIf="memo.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>

    <label dougsFormFieldLabel>
      Service d'encaissement utilisé
      <i class="fal fa-question-circle help" (click)="showServiceTypeHelpModal()"></i>
    </label>
    <dougs-form-field>
      <dougs-select
        [searchable]="true"
        (select)="serviceSelected($event)"
        dougsFormFieldControl
        formControlName="type"
        placeholder="Sélectionnez un service"
      >
        <dougs-select-option
          *ngFor="
            let paymentsProcessorConfiguration of paymentsProcessorStateService.paymentsProcessorsConfigurationList$
              | async
          "
          [value]="paymentsProcessorConfiguration.value"
        >
          {{ paymentsProcessorConfiguration.label }}
        </dougs-select-option>
      </dougs-select>
      <span *ngIf="formService.isControlInvalid(type)" dougsFormFieldError>
        <span *ngIf="type.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>

    <dougs-panel-info class="mt-16" *ngIf="mustSynchronize$ | async">
      <div class="info">
        Bonne nouvelle ! Nous pouvons nous synchroniser avec ce service de paiement. Si vous n'avez pas encore connecté
        vos comptes, rendez-vous dans la section Compte Synchronisé de vos paramètres.
      </div>
    </dougs-panel-info>

    <dougs-form-field class="mt-16" *ngIf="mustSynchronize$ | async">
      <label dougsFormFieldLabel>Compte synchronisé</label>
      <dougs-select
        [searchable]="true"
        dougsFormFieldControl
        formControlName="synchronizedAccountId"
        placeholder="Sélectionnez votre compte synchronisé"
      >
        <dougs-select-option
          *ngFor="let synchronizedAccount of synchronizableAccounts$ | async; trackBy: trackById"
          [value]="synchronizedAccount.id"
        >
          {{ synchronizedAccount.name }} - {{ synchronizedAccount.memo }}
        </dougs-select-option>
      </dougs-select>
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Ajouter</dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="isLoading">Création en cours...</dougs-loader-fullpage>
