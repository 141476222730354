import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SalesViaPlatformConfiguration } from '@dougs/company/dto';
import {
  ButtonComponent,
  DividerComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';

@Component({
  selector: 'dougs-sales-via-platform-info-modal',
  templateUrl: './sales-via-platform-info-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    PanelInfoComponent,
    DividerComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class SalesViaPlatformInfoModalComponent {
  isDropShipper = false;
  salesViaPlatformConfiguration: SalesViaPlatformConfiguration;
  originalPlan: SubscriptionPlan | null;
  newPlan: SubscriptionPlan | null;

  constructor(
    @Inject(MODAL_DATA)
    data: {
      salesViaPlatformConfiguration: SalesViaPlatformConfiguration;
      originalPlan: SubscriptionPlan | null;
      newPlan: SubscriptionPlan | null;
    },
  ) {
    this.salesViaPlatformConfiguration = data.salesViaPlatformConfiguration;
    this.originalPlan = data.originalPlan;
    this.newPlan = data.newPlan;
    this.isDropShipper = !!(
      this.salesViaPlatformConfiguration.dropShippingUnder150FromMarketPlace ||
      this.salesViaPlatformConfiguration.dropShippingUnder150FromPersonalWeb ||
      this.salesViaPlatformConfiguration.dropShippingOver150
    );
  }

  buildNewPlanWordingFeatures(): string {
    if (!this.newPlan) {
      return '';
    }

    const sortedOptions: string[] = ['none', 'basic', 'ossOrIoss'];
    const newPlanIndex: number = Math.max(sortedOptions.indexOf(this.newPlan.options?.ecommerce?.value || ''), 0);
    const originalPlanIndex: number = Math.max(
      sortedOptions.indexOf(this.originalPlan?.options?.ecommerce?.value || ''),
      0,
    );
    const isUpgrade: boolean = newPlanIndex > originalPlanIndex;
    const adjustmentAmount: number | null = this.newPlan.adjustment ? this.newPlan.adjustment.amount : null;

    return `Vous venez ${isUpgrade ? "d'activer" : 'de désactiver'} l'extension
     e-commerce de votre pack ${this.newPlan.group}. ${
       isUpgrade
         ? `Le nouveau tarif est de ${this.newPlan.amount}€ HT/mois et s'appliquera sur la facture du mois prochain. ${
             adjustmentAmount
               ? `Vous aurez également un rattrapage de
                ${this.newPlan.adjustment.amount.toFixed(2)} € HT correspondant à ce pack.`
               : ''
           }`
         : `Vous bénéficiez du pack ${this.newPlan.name} au tarif de ${this.newPlan.amount}€ HT/mois qui s'appliquera sur la facture du mois prochain.`
     }`;
  }
}
