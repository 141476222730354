<ng-container *ngIf="ecommerceSalesChannelFormService.mustIndicateStock$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.onCategoryChanges$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.disableControlsOnEdition$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.toggleEndDate$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.togglePaymentsProcessor$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.toggleShopUrl$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.isSynchronizable$ | async" />
<ng-container *ngIf="ecommerceSalesChannelFormService.updateShopTypeLabel$ | async" />

@if ({ salesChannel: ecommerceSalesChannelFormService.salesChannel$ | async }; as data) {
  <div [formGroup]="ecommerceSalesChannelFormService.formGroup">
    <h5 class="mb-16">Informations</h5>
    <dougs-form-field>
      <label dougsFormFieldLabel>
        Nom de votre canal de vente
        <i class="fal fa-question-circle help" (click)="showHelpModal()"></i>
      </label>
      <input dougsFormFieldControl type="text" formControlName="memo" placeholder="Ma boutique" />
      @if (
        formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.memo) &&
        ecommerceSalesChannelFormService.formGroup.controls.memo.hasError('required')
      ) {
        <span dougsFormFieldError>Ce champ est requis</span>
      }
    </dougs-form-field>
    <dougs-form-field>
      <label dougsFormFieldLabel>Vous vendez via</label>
      <dougs-select
        dougsFormFieldControl
        formControlName="category"
        placeholder="Sélectionnez dans la liste le type de canal de vente"
      >
        <dougs-select-option value="marketPlace">Une marketplace (Amazon, Uber, Airbnb, etc.)</dougs-select-option>
        <dougs-select-option value="website">
          Votre propre site en ligne (Shopify, WooCommerce, Wix, etc.)
        </dougs-select-option>
        <dougs-select-option value="physicalPlace">
          Un lieu physique (boutique, taxi, atelier, etc.)
        </dougs-select-option>
      </dougs-select>
      @if (
        formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.category) &&
        ecommerceSalesChannelFormService.formGroup.controls.category.hasError('required')
      ) {
        <span dougsFormFieldError>Ce champ est requis</span>
      }
    </dougs-form-field>
    @if (ecommerceSalesChannelFormService.formGroup.controls.category.value) {
      <dougs-ecommerce-sales-channel-form-information
        [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
        [creating]="!data.salesChannel"
      />
      @if (ecommerceSalesChannelFormService.showSyncForm$()) {
        <dougs-ecommerce-shop-sync-form
          [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
          (syncInfoModalOpened)="ecommerceSalesChannelFormService.sendSyncInfoModalMetrics(data.salesChannel)"
        />
      }
      @if (ecommerceSalesChannelFormService.showPaymentProcessors$()) {
        <dougs-ecommerce-sales-channel-form-processors
          [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
          (addPaymentsProcessorWithDate)="ecommerceSalesChannelFormService.addPaymentsProcessorWithDate()"
          (deletePaymentsProcessorWithDate)="ecommerceSalesChannelFormService.deletePaymentsProcessorWithDate($event)"
        />
      }
      <dougs-divider class="mt-16" />
      <dougs-ecommerce-sales-channel-form-sales
        [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
      />
      @if (data.salesChannel) {
        <dougs-divider class="mt-16" />
        <dougs-ecommerce-sales-channel-form-dates
          [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
        />
      }
    }
    @if (data.salesChannel) {
      <dougs-divider class="mb-16" />
      <p class="tiny color-gray-350 mb-16">
        Voulez-vous supprimer ce canal de vente ?
        <a
          href="#"
          class="color-error"
          (click)="$event.preventDefault(); $event.stopPropagation(); deleteSalesChannel(data.salesChannel)"
        >
          Supprimer
        </a>
      </p>
    }
  </div>
}
