import { AbstractControl, ValidatorFn } from '@angular/forms';

export const shouldHaveOneStock = (): ValidatorFn => {
  return (formGroup: AbstractControl) => {
    if (formGroup.get('sellsMerchandise')?.value || formGroup.get('sellsFinishedProduct')?.value) {
      if (
        formGroup.get('fromFrance')?.value === false &&
        formGroup.get('fromEu')?.value === false &&
        formGroup.get('fromOutsideEu')?.value === false
      ) {
        return { shouldHaveOneStock: true };
      }
    }
    return null;
  };
};
