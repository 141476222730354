import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  PanelInfoComponent,
  PillComponent,
  TooltipDirective,
} from '@dougs/ds';
import { EcommerceSalesChannelFormService } from '../../../../../forms/ecommerce-sales-channel-form.service';
import { EcommerceShopSyncFormComponentService } from '../../../../../services/ecommerce-shop-sync-form.component.service';
import { EcommerceBetaPillComponent } from '../../../../ecommerce-beta-pill/ecommerce-beta-pill.component';
import { EcommerceShopUrlComponent } from './ecommerce-shop-url/ecommerce-shop-url.component';

@Component({
  selector: 'dougs-ecommerce-shop-sync-form',
  templateUrl: 'ecommerce-shop-sync-form.component.html',
  styleUrls: ['ecommerce-shop-sync-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    PanelInfoComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    AsyncPipe,
    ErrorFormFieldDirective,
    PillComponent,
    ButtonComponent,
    CheckboxComponent,
    EcommerceShopUrlComponent,
    TooltipDirective,
    EcommerceBetaPillComponent,
  ],
  providers: [EcommerceShopSyncFormComponentService],
})
export class EcommerceShopSyncFormComponent {
  @Output()
  syncInfoModalOpened: EventEmitter<void> = this.ecommerceShopSyncFormComponentService.syncInfoModalOpened;

  constructor(
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
    public readonly ecommerceShopSyncFormComponentService: EcommerceShopSyncFormComponentService,
    public readonly companyStateService: CompanyStateService,
    public readonly formService: FormService,
  ) {}
}
