import { Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { MetricsService } from '@dougs/core/metrics';

@Injectable({ providedIn: 'root' })
export class EcommerceComponentService {
  constructor(
    private readonly intercom: Intercom,
    private readonly metricsService: MetricsService,
  ) {}

  openIntercom(e: Event): void {
    e.preventDefault();
    this.intercom.show();
  }

  onResourceLinkClick(): void {
    this.metricsService.pushMixpanelEvent(`Settings e-commerce OSS-iOSS helper clicked`);
  }
}
