import { AbstractControl, ValidatorFn } from '@angular/forms';

export const shouldHaveOnePaymentProcessor = (): ValidatorFn => {
  return (formArray: AbstractControl) => {
    if (formArray.parent?.get('category')?.value === 'website' && formArray.value.length === 0) {
      return { shouldHaveOnePaymentProcessor: true };
    }
    return null;
  };
};
