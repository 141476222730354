import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { CompanyStateService } from '@dougs/company/shared';
import { FormService } from '@dougs/core/form';
import { ScrollAccordionService } from '@dougs/core/scroll-accordion';
import {
  ConfirmationModalComponent,
  ControlFormFieldDirective,
  DetectFormChangesDirective,
  DividerComponent,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  ModalService,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { SalesChannel, SalesChannelCategory } from '@dougs/ecommerce/dto';
import { PaymentsProcessorStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
import { HelpFieldModalComponent } from '@dougs/fields/ui';
import { EcommerceSalesChannelFormService } from '../../../../forms/ecommerce-sales-channel-form.service';
import { EcommerceSalesChannelFormDatesComponent } from './ecommerce-sales-channel-form-dates/ecommerce-sales-channel-form-dates.component';
import { EcommerceSalesChannelFormInformationComponent } from './ecommerce-sales-channel-form-information/ecommerce-sales-channel-form-information.component';
import { EcommerceSalesChannelFormProcessorsComponent } from './ecommerce-sales-channel-form-processors/ecommerce-sales-channel-form-processors.component';
import { EcommerceSalesChannelFormSalesComponent } from './ecommerce-sales-channel-form-sales/ecommerce-sales-channel-form-sales.component';
import { EcommerceShopSyncFormComponent } from './ecommerce-shop-sync-form/ecommerce-shop-sync-form.component';

@Component({
  selector: 'dougs-ecommerce-sales-channel-form',
  templateUrl: './ecommerce-sales-channel-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
    EcommerceSalesChannelFormInformationComponent,
    DetectFormChangesDirective,
    PanelInfoComponent,
    EcommerceShopSyncFormComponent,
    EcommerceSalesChannelFormProcessorsComponent,
    DividerComponent,
    EcommerceSalesChannelFormSalesComponent,
    EcommerceSalesChannelFormDatesComponent,
    AsyncPipe,
  ],
})
export class EcommerceSalesChannelFormComponent implements OnInit {
  @Input()
  set salesChannel(salesChannel: SalesChannel | undefined) {
    void this.ecommerceSalesChannelFormService.setSalesChannel(salesChannel);
  }

  SalesChannelCategory = SalesChannelCategory;

  constructor(
    public readonly companyStateService: CompanyStateService,
    public formService: FormService,
    public readonly paymentsProcessorStateService: PaymentsProcessorStateService,
    public readonly salesChannelStateService: SalesChannelStateService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
    private readonly modalService: ModalService,
    private readonly scrollAccordionService: ScrollAccordionService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.paymentsProcessorStateService.refreshPaymentsProcessors(this.companyStateService.activeCompany);
    await this.salesChannelStateService.refreshMarketPlacesConfiguration();
    await this.salesChannelStateService.refreshEcommerceWebsiteSoftwareConfiguration();
  }

  showHelpModal(): void {
    this.modalService.open(HelpFieldModalComponent, {
      data: {
        title: 'Nom de votre canal de vente',
        body: 'Le nom que vous voulez voir dans Dougs',
      },
    });
  }

  async deleteSalesChannel(salesChannel: SalesChannel): Promise<void> {
    const success: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService
          .open<boolean>(ConfirmationModalComponent, {
            data: {
              title: 'Supprimer le canal de vente',
              body: `Êtes-vous sûr de vouloir supprimer votre canal de vente ? Cette action est définitive.`,
              noText: 'Annuler',
              yesText: 'Oui, supprimer mon canal de vente',
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    ).data;

    if (success) {
      await this.salesChannelStateService.deleteSalesChannel(salesChannel);
      this.scrollAccordionService.scrollToId('sales-channel-content');
    }
  }
}
