import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MetricsService } from '@dougs/core/metrics';
import { BlankStateComponent, ButtonComponent, CheckboxComponent } from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { SalesChannelStateService } from '@dougs/ecommerce/shared';
import { SalesChannelListService } from '../../services/sales-channel-list.service';
import { EcommerceSalesChannelAddComponent } from './ecommerce-sales-channel/ecommerce-sales-channel-add/ecommerce-sales-channel-add.component';
import { EcommerceSalesChannelItemComponent } from './ecommerce-sales-channel-item/ecommerce-sales-channel-item.component';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-ecommerce-sales-channels',
  templateUrl: './ecommerce-sales-channels.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CheckboxComponent,
    FormsModule,
    NgFor,
    EcommerceSalesChannelItemComponent,
    NgIf,
    BlankStateComponent,
    EcommerceSalesChannelAddComponent,
    ButtonComponent,
    AsyncPipe,
  ],
  providers: [SalesChannelListService],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: 0, marginTop: 0, opacity: 0, overflow: 'hidden' }),
        animate(ANIMATION, style({ height: '*', marginTop: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ overflow: 'hidden' }),
        animate(ANIMATION, style({ height: 0, marginTop: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class EcommerceSalesChannelsComponent {
  constructor(
    public readonly salesChannelStateService: SalesChannelStateService,
    public readonly salesChannelListService: SalesChannelListService,
    private readonly metricsService: MetricsService,
  ) {}

  trackById = (_: number, salesChannel: SalesChannel): number => salesChannel.id;

  addNewSalesChannel(): void {
    this.salesChannelListService.isCreating = true;
    this.salesChannelListService.setEditedSalesChannelId();
    this.metricsService.pushMixpanelEvent('e-Commerce Sales Channels Created', {
      'CTA Location': 'Settings Module',
    });
  }
}
