import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import { CheckboxComponent, InputDatepickerComponent } from '@dougs/ds';
import { EcommerceSalesChannelFormService } from '../../../../../forms/ecommerce-sales-channel-form.service';

@Component({
  selector: 'dougs-ecommerce-sales-channel-form-dates',
  templateUrl: './ecommerce-sales-channel-form-dates.component.html',
  styleUrls: ['./ecommerce-sales-channel-form-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CheckboxComponent, NgIf, InputDatepickerComponent],
})
export class EcommerceSalesChannelFormDatesComponent {
  constructor(
    public formService: FormService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
  ) {}

  onNotUsedAnymoreChange(notUsedAnymore: boolean): void {
    if (!notUsedAnymore) {
      this.ecommerceSalesChannelFormService.formGroup.controls.endDate.setValue(null, { emitEvent: false });
      this.ecommerceSalesChannelFormService.formGroup.controls.endDate.disable({ emitEvent: false });
    } else {
      this.ecommerceSalesChannelFormService.formGroup.controls.endDate.enable({ emitEvent: false });
    }
  }
}
