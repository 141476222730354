<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.ossActivationDateValuesChanges$ | async"></ng-container>
  <ng-container *ngIf="ecommerceFormService.iossActivationDateValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <ng-container *ngIf="company.accountingConfiguration.hasOss">
      <h5 class="mb-8">
        OSS <i (click)="ecommerceFormService.openOSSInfoModal()" class="fal fa-question-circle pointer"></i>
      </h5>
      <div class="form-layout mb-8">
        <dougs-input-datepicker
          [noMargin]="true"
          label="Je suis inscrit au guichet unique OSS-UE pour mes ventes à distance à partir du :"
          formControlName="ossActivationDate"
        ></dougs-input-datepicker>
      </div>
      <div class="form-layout mb-16">
        <div>
          <label dougsFormFieldLabel class="question">Bordereau d'inscription OSS</label>
          <div class="file-list">
            <dougs-file-input
              (uploadFiles)="ecommerceFormService.uploadOssFiles(company, $event)"
              fileInputText="Attacher"
            ></dougs-file-input>
            <dougs-file-pill
              [canDelete]="!company.salesViaPlatformConfiguration.dateLocked"
              (deleteFile)="ecommerceFormService.deleteOssFile(company, $event)"
              *ngFor="let attachment of company.ossInscriptions; trackBy: trackByAttachmentId"
              [attachment]="attachment"
            ></dougs-file-pill>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="company.accountingConfiguration.hasIoss">
      <h5 class="mb-8">
        IOSS <i (click)="ecommerceFormService.openIOSSInfoModal()" class="fal fa-question-circle pointer"></i>
      </h5>
      <div class="form-layout mb-8">
        <dougs-input-datepicker
          [noMargin]="true"
          label="Je suis inscrit au guichet unique IOSS pour les ventes à distance de biens importés, vendus depuis mon propre site internet, à partir du :"
          formControlName="iossActivationDate"
        ></dougs-input-datepicker>
      </div>
      <div class="form-layout mb-16">
        <div>
          <label dougsFormFieldLabel class="question">Bordereau d'inscription IOSS</label>
          <div class="file-list">
            <dougs-file-input
              (uploadFiles)="ecommerceFormService.uploadIOssFiles(company, $event)"
              fileInputText="Attacher"
            ></dougs-file-input>
            <dougs-file-pill
              [canDelete]="!company.salesViaPlatformConfiguration.dateLocked"
              (deleteFile)="ecommerceFormService.deleteIOssFile(company, $event)"
              *ngFor="let attachment of company.iossInscriptions; trackBy: trackByAttachmentId"
              [attachment]="attachment"
            ></dougs-file-pill>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
