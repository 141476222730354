import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, concatMap, filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { ScrollAccordionService } from '@dougs/core/scroll-accordion';
import { FlashMessagesService } from '@dougs/ds';
import { SalesChannel, SalesChannelCategory, ShopifyAuthenticatedSession } from '@dougs/ecommerce/dto';
import { canSynchronizeShop, ShopifyStateService } from '@dougs/ecommerce/shared';
import { SalesChannelListService } from './sales-channel-list.service';

@Injectable()
export class EcommerceSalesChannelItemComponentService {
  constructor(
    public readonly salesChannelListService: SalesChannelListService,
    private readonly companyStateService: CompanyStateService,
    private readonly shopifyStateService: ShopifyStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly scrollAccordionService: ScrollAccordionService,
  ) {}

  private readonly _salesChannelSubject: BehaviorSubject<SalesChannel | undefined> = new BehaviorSubject<
    SalesChannel | undefined
  >(undefined);
  private _editedSalesChannelId?: number;
  private _paymentProcessorsWithDate?: string;
  private readonly isSyncLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isSyncLoading$: Observable<boolean> = this.isSyncLoadingSubject.asObservable();
  salesChannel$: Observable<SalesChannel | undefined> = this._salesChannelSubject.asObservable();
  isEditedChannel$: Observable<boolean> = combineLatest([
    this.salesChannelListService.editedChannelIdChange$,
    this.salesChannel$,
  ]).pipe(map(([id, salesChannel]) => salesChannel?.id === id));
  isSynchronizable$: Observable<boolean> = combineLatest([
    this.salesChannel$,
    this.companyStateService.activeCompany$,
  ]).pipe(map(([salesChannel, company]) => canSynchronizeShop(salesChannel?.type || '', company)));
  isSynchronized$: Observable<boolean> = combineLatest([this.salesChannel$, this.isSynchronizable$]).pipe(
    filter(([salesChannel, isSynchronizable]) => !!salesChannel && isSynchronizable),
    concatMap(([salesChannel, _]) =>
      this.shopifyStateService.isAuthenticated(
        (salesChannel as SalesChannel).companyId,
        (salesChannel as SalesChannel).id,
      ),
    ),
    map((result: ShopifyAuthenticatedSession | undefined) => result?.isAuthenticated || false),
  );

  set salesChannel(salesChannel: SalesChannel) {
    this._salesChannelSubject.next(salesChannel);

    if (salesChannel?.category === SalesChannelCategory.WEBSITE) {
      this._paymentProcessorsWithDate = salesChannel.paymentsProcessorsWithDate
        ?.map((payment) => payment.paymentsProcessor.name)
        .join(', ');
    }
  }

  set editedSalesChannelId(id: number | undefined) {
    this._editedSalesChannelId = id;
  }

  get paymentProcessorsWithDate(): string | undefined {
    return this._paymentProcessorsWithDate;
  }

  setEditedSalesChannelId(): void {
    this.salesChannelListService.setEditedSalesChannelId(this._salesChannelSubject.value);
  }

  async refreshShopifyData(): Promise<void> {
    this.isSyncLoadingSubject.next(true);
    await this.shopifyStateService.resyncData(
      this._salesChannelSubject.value?.companyId ?? 0,
      this._editedSalesChannelId ?? 0,
    );
    this.isSyncLoadingSubject.next(false);
    this.flashMessagesService.show('Les données ont été réimportées avec succès.', {
      type: 'success',
    });
  }

  scrollToSalesChannelTop(salesChannelId: number): void {
    this.scrollAccordionService.scrollToId('sales-channel-' + salesChannelId);
  }
}
