<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.euIndividualRevenueOver10000ValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Concernant vos ventes à des particuliers en UE (hors France), pensez-vous avoir fait plus de 10 000 € de ventes
        l'année civile passée ou faire plus de 10 000 € de ventes cette année&nbsp;?
      </label>
      <dougs-radio-group formControlName="euIndividualRevenueOver10000" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
  </ng-container>
</ng-container>
