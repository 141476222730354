import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValid, parse } from 'date-fns';

export const isDate = (): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    const parsedDate = parse(control.value, 'yyyy-MM-dd', new Date());
    if (isValid(parsedDate) && parsedDate.getFullYear() > 1900) {
      return null;
    }

    return { isDate: true };
  };
};
