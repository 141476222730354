<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.individualSalesFromUeStockValuesChanges$ | async"></ng-container>
  <ng-container *ngIf="ecommerceFormService.individualSalesFromFrenchStockValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous de la vente à des particuliers vers un autre pays de l'Union Européenne que la France depuis un
        stock de biens situé en France&nbsp;?
      </label>
      <dougs-radio-group formControlName="individualSalesFromFrenchStock" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous de la vente à des particuliers en Union Européenne depuis un stock de biens situé dans un autre pays
        de l'Union Européenne que la France&nbsp;?
      </label>
      <dougs-radio-group formControlName="individualSalesFromUeStock" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
  </ng-container>
</ng-container>
