import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';

@Component({
  selector: 'dougs-add-sales-channel-amazon-modal',
  templateUrl: './add-sales-channel-amazon-modal.component.html',
  styleUrls: ['./add-sales-channel-amazon-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    AvatarMessageComponent,
  ],
})
export class AddSalesChannelModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      salesChannel: SalesChannel;
    },
  ) {}
}
