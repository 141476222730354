import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const individualSalesValidator: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
  const individualEuropeanSalesControl: AbstractControl | null = formGroup.get('individualEuropeanSales');
  if (individualEuropeanSalesControl && individualEuropeanSalesControl.value) {
    const dropShippingOver150Control: AbstractControl | null = formGroup.get('dropShippingOver150');
    const dropShippingUnder150FromPersonalWebControl: AbstractControl | null = formGroup.get(
      'dropShippingUnder150FromPersonalWeb',
    );
    const dropShippingUnder150FromMarketPlaceControl: AbstractControl | null = formGroup.get(
      'dropShippingUnder150FromMarketPlace',
    );
    const individualSalesFromFrenchStockControl: AbstractControl | null = formGroup.get(
      'individualSalesFromFrenchStock',
    );
    const individualSalesFromUeStockControl: AbstractControl | null = formGroup.get('individualSalesFromUeStock');
    const individualServicesEuSalesControl: AbstractControl | null = formGroup.get('individualServicesEuSales');

    if (
      dropShippingOver150Control &&
      !dropShippingOver150Control.value &&
      dropShippingUnder150FromPersonalWebControl &&
      !dropShippingUnder150FromPersonalWebControl.value &&
      dropShippingUnder150FromMarketPlaceControl &&
      !dropShippingUnder150FromMarketPlaceControl.value &&
      individualSalesFromFrenchStockControl &&
      !individualSalesFromFrenchStockControl.value &&
      individualSalesFromUeStockControl &&
      !individualSalesFromUeStockControl.value &&
      individualServicesEuSalesControl &&
      !individualServicesEuSalesControl.value
    ) {
      return { individualSalesError: 'Vous devez répondre oui à au moins une des questions suivantes.' };
    }
  }

  return null;
};
