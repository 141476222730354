import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';

@Injectable({
  providedIn: 'root',
})
export class EcommerceOpenSynchronizedShopModalService {
  constructor(private readonly modalService: ModalService) {}

  async openSynchronizedShopModal(shopUrl: string): Promise<void> {
    const { EcommerceSynchronizedShopModalComponent } = await import(
      '../modals/ecommerce-synchronized-shop-modal/ecommerce-synchronized-shop-modal.component'
    );
    this.modalService.open(EcommerceSynchronizedShopModalComponent, { data: { shopUrl } });
  }
}
