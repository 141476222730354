<dougs-accordion icon="fal fa-desktop" [isLocked]="true" [isOpen]="true" [showFooter]="true">
  <dougs-accordion-title>
    <h6>Plateforme e-commerce - Ma boutique</h6>
  </dougs-accordion-title>
  <form [formGroup]="ecommerceSalesChannelFormService.formGroup">
    <dougs-ecommerce-sales-channel-form
      [dougsDetectFormChanges]="ecommerceSalesChannelFormService.formGroupHasBeenTouched$ | async"
    ></dougs-ecommerce-sales-channel-form>
  </form>
  <dougs-accordion-footer>
    <dougs-button color="secondary" (click)="salesChannelListService.abortEdition($event)" class="mr-8">
      Annuler
    </dougs-button>
    <dougs-button (click)="ecommerceSalesChannelFormService.onSubmit()">Sauvegarder</dougs-button>
  </dougs-accordion-footer>
</dougs-accordion>
