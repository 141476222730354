import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import { ModalService, PanelInfoComponent, RadioComponent, RadioGroupComponent } from '@dougs/ds';
import { HelpFieldModalComponent } from '@dougs/fields/ui';
import { EcommerceSalesChannelFormService } from '../../../../../forms/ecommerce-sales-channel-form.service';
import { EcommerceSalesChannelRequiredControlComponent } from './ecommerce-sales-channel-required-control/ecommerce-sales-channel-required-control.component';

@Component({
  selector: 'dougs-ecommerce-sales-channel-form-sales',
  templateUrl: './ecommerce-sales-channel-form-sales.component.html',
  styleUrls: ['./ecommerce-sales-channel-form-sales.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    AsyncPipe,
    PanelInfoComponent,
    EcommerceSalesChannelRequiredControlComponent,
    RadioGroupComponent,
    RadioComponent,
  ],
})
export class EcommerceSalesChannelFormSalesComponent {
  constructor(
    public formService: FormService,
    public readonly ecommerceSalesChannelFormService: EcommerceSalesChannelFormService,
    private readonly modalService: ModalService,
  ) {}

  showMerchandiseHelpModal(): void {
    this.modalService.open(HelpFieldModalComponent, {
      data: {
        title: 'Marchandise',
        body: "La vente de marchandise concerne la revente de biens que l'entreprise a achetés puis vendus sans transformation. Par exemple, un magasin de vêtements qui achète des habits auprès de fabricants pour les revendre à ses clients pratique la vente de marchandise.",
      },
    });
  }

  showFinishedProductHelpModal(): void {
    this.modalService.open(HelpFieldModalComponent, {
      data: {
        title: 'Produit fini',
        body: "La vente de produits finis concerne la vente de biens qui ont été fabriqués ou transformés par l'entreprise avant d'être vendus. Par exemple, une entreprise qui produit des meubles et les vend directement aux consommateurs pratique la vente de produits finis.",
      },
    });
  }

  showServiceHelpModal(): void {
    this.modalService.open(HelpFieldModalComponent, {
      data: {
        title: 'Produit fini',
        body: "La vente de service concerne la fourniture d'une expertise ou d'un travail sans produire de bien par l'entreprise. Par exemple, du transport de personnes, des consultations, de la location immobilière, des formations e-learning, de la vente d'applications mobiles ou de livres numériques.",
      },
    });
  }
}
