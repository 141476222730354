<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.dropShippingOver150ValuesChanges$ | async"></ng-container>
  <ng-container *ngIf="ecommerceFormService.dropShippingUnder150FromPersonalWebValuesChanges$ | async"></ng-container>
  <ng-container *ngIf="ecommerceFormService.dropShippingUnder150FromMarketPlaceValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous du dropshipping en Union Européenne (France comprise) de colis d'une valeur supérieure à 150€&nbsp;?
      </label>
      <dougs-radio-group formControlName="dropShippingOver150" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous du dropshipping en Union Européenne (France comprise) de colis d'une valeur inférieur à 150€ depuis
        votre propre site web&nbsp;?
      </label>
      <dougs-radio-group formControlName="dropShippingUnder150FromPersonalWeb" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous du dropshipping en Union Européenne (France comprise) de colis d'une valeur inférieur à 150€ depuis
        une marketplace&nbsp;?
      </label>
      <dougs-radio-group formControlName="dropShippingUnder150FromMarketPlace" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
    <div class="form-layout mb-16">
      <dougs-divider></dougs-divider>
    </div>
  </ng-container>
</ng-container>
