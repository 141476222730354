import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { ButtonComponent, CheckboxComponent, DividerComponent, ModalService, PanelInfoComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { EcommerceFormService } from '../forms/ecommerce-form.service';
import { SalesViaPlatformInfoModalComponent } from '../modals/sales-via-platform-info-modal/sales-via-platform-info-modal.component';
import { EcommerceComponentService } from '../services/ecommerce.component.service';
import { EcommerceFormComponent } from './ecommerce-form/ecommerce-form.component';
import { EcommerceInscriptionComponent } from './ecommerce-form/ecommerce-inscription/ecommerce-inscription.component';

@Component({
  selector: 'dougs-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CheckboxComponent,
    EcommerceFormComponent,
    DividerComponent,
    ButtonComponent,
    EcommerceInscriptionComponent,
    AsyncPipe,
    PanelInfoComponent,
  ],
  providers: [EcommerceFormService],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          overflow: 'hidden',
          opacity: 0,
        }),
        animate('300ms ease-in-out', style({ height: '*', paddingTop: '*', paddingBottom: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({
          height: '*',
          paddingTop: '*',
          paddingBottom: '*',
          overflow: 'hidden',
        }),
        animate('300ms ease-in-out', style({ height: 0, paddingTop: 0, paddingBottom: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class EcommerceComponent {
  canValidateSettings = false;
  isLoading = false;

  private _company!: Company;
  @Input()
  set company(company: Company) {
    this._company = company;

    if (company) {
      this.ecommerceFormService.formatForm(company);
      this.ecommerceFormService.populateForm(company);
    }

    this.canValidateSettings = !!this.company.salesViaPlatformConfiguration.canLock;
  }

  get company(): Company {
    return this._company;
  }

  constructor(
    public userStateService: UserStateService,
    public ecommerceFormService: EcommerceFormService,
    private readonly modalService: ModalService,
    public readonly ecommerceComponentService: EcommerceComponentService,
  ) {}

  openSalesViaPlatformInfoModal(): void {
    this.modalService.open(SalesViaPlatformInfoModalComponent, {
      data: { salesViaPlatformConfiguration: this.company.salesViaPlatformConfiguration },
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.isLoading) {
      this.isLoading = true;
      await this.ecommerceFormService.onSubmit(this.company, this.canValidateSettings);
      this.isLoading = false;
    }
  }
}
