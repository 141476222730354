import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import {
  AccordionComponent,
  AccordionDescriptionDirective,
  AccordionFooterDirective,
  AccordionTitleDirective,
  ButtonComponent,
  DividerComponent,
  DougsDatePipe,
  PillComponent,
} from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { UserStateService } from '@dougs/user/shared';
import { EcommerceSalesChannelFormService } from '../../../forms/ecommerce-sales-channel-form.service';
import { SalesChannelIconPipe } from '../../../pipes';
import { EcommerceSalesChannelItemComponentService } from '../../../services/ecommerce-sales-channel-item.component.service';
import { SalesChannelListService } from '../../../services/sales-channel-list.service';
import { EcommerceSalesChannelComponent } from '../ecommerce-sales-channel/ecommerce-sales-channel.component';

@Component({
  selector: 'dougs-sales-channel-item',
  templateUrl: './ecommerce-sales-channel-item.component.html',
  styleUrls: ['./ecommerce-sales-channel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    PillComponent,
    EcommerceSalesChannelComponent,
    DividerComponent,
    AsyncPipe,
    SalesChannelIconPipe,
    AccordionComponent,
    AccordionTitleDirective,
    AccordionDescriptionDirective,
    AccordionFooterDirective,
    ButtonComponent,
    DougsDatePipe,
  ],
  providers: [EcommerceSalesChannelItemComponentService, EcommerceSalesChannelFormService],
})
export class EcommerceSalesChannelItemComponent {
  @Input() set editedSalesChannelId(id: number | undefined) {
    this.ecommerceSalesChannelItemComponentService.editedSalesChannelId = id;
  }

  @Input()
  set salesChannel(salesChannel: SalesChannel) {
    this.ecommerceSalesChannelItemComponentService.salesChannel = salesChannel;
  }

  @ViewChild(EcommerceSalesChannelComponent) salesChannelComponent: EcommerceSalesChannelComponent | undefined;

  constructor(
    public readonly ecommerceSalesChannelItemComponentService: EcommerceSalesChannelItemComponentService,
    public readonly salesChannelListService: SalesChannelListService,
    public readonly userStateService: UserStateService,
  ) {}
}
