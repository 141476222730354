<dougs-accordion
  *ngIf="ecommerceSalesChannelItemComponentService.salesChannel$ | async as salesChannel"
  [image]="(salesChannel.type | salesChannelIcon) || ''"
  [icon]="'fal fa-desktop'"
  [isOpen]="ecommerceSalesChannelItemComponentService.isEditedChannel$ | async"
  [showFooter]="true"
  [id]="'sales-channel-' + salesChannel.id"
  (open)="ecommerceSalesChannelItemComponentService.setEditedSalesChannelId()"
  class="mb-16"
>
  <dougs-accordion-title>
    <h6>{{ salesChannel?.name }}</h6>
  </dougs-accordion-title>
  <dougs-accordion-description>
    <p *ngIf="ecommerceSalesChannelItemComponentService.paymentProcessorsWithDate" class="small mb-8">
      {{ ecommerceSalesChannelItemComponentService.paymentProcessorsWithDate }}
    </p>
    <dougs-pill type="error" *ngIf="salesChannel?.endDate" class="mr-4">
      Canal de vente fermé le {{ salesChannel.endDate | date: 'dd/MM/yyyy' }}
    </dougs-pill>
    <dougs-pill
      type="admin"
      *ngIf="salesChannel?.accountNumber && (userStateService.loggedInUser$ | async).isAccountantOrAdmin"
      class="mr-4"
    >
      {{ salesChannel?.accountNumber }}
    </dougs-pill>
    <ng-container *ngIf="ecommerceSalesChannelItemComponentService.isSynchronizable$ | async">
      <dougs-pill
        type="success"
        *ngIf="ecommerceSalesChannelItemComponentService.isSynchronized$ | async; else notSync"
        class="mr-4"
      >
        <i class="fal fa-check-circle mr-4"></i>
        Boutique synchronisée
      </dougs-pill>
      <ng-template #notSync>
        <dougs-pill type="warning" class="mr-4">
          <i class="fal fa-circle-xmark mr-4"></i>
          Boutique non synchronisée
        </dougs-pill>
      </ng-template>
    </ng-container>
  </dougs-accordion-description>
  <dougs-ecommerce-sales-channel
    *ngIf="ecommerceSalesChannelItemComponentService.isEditedChannel$ | async"
    [salesChannel]="salesChannel"
  ></dougs-ecommerce-sales-channel>
  <dougs-accordion-footer>
    <dougs-button
      (click)="
        salesChannelComponent?.salesChannelListService.abortEdition($event);
        ecommerceSalesChannelItemComponentService.scrollToSalesChannelTop(salesChannel.id)
      "
      color="secondary"
      class="mr-8"
      >Annuler
    </dougs-button>
    <dougs-button
      *ngIf="
        (ecommerceSalesChannelItemComponentService.isSynchronized$ | async) &&
        (userStateService.loggedInUser$ | async).isAccountantOrAdmin
      "
      [disabled]="ecommerceSalesChannelItemComponentService.isSyncLoading$ | async"
      color="admin"
      (click)="ecommerceSalesChannelItemComponentService.refreshShopifyData()"
      class="mr-8"
    >
      Réimporter
      <i *ngIf="ecommerceSalesChannelItemComponentService.isSyncLoading$ | async" class="mr-8 fas fa-spinner"></i>
    </dougs-button>
    <dougs-button
      (click)="salesChannelComponent?.formEcommerceSalesChannelService.onSubmit(salesChannel)"
      color="primary"
      >Enregistrer
    </dougs-button>
  </dougs-accordion-footer>
</dougs-accordion>
