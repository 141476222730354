import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PillComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-ecommerce-beta-pill',
  standalone: true,
  imports: [CommonModule, PillComponent],
  templateUrl: './ecommerce-beta-pill.component.html',
  styleUrl: './ecommerce-beta-pill.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcommerceBetaPillComponent {
  @Input() isLight = false;
}
