import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, from, lastValueFrom, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, map } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { FormSettingsService } from '@dougs/core/form';
import { OwnershipService } from '@dougs/core/ownership';
import { FlashMessagesService, HelpModalComponent, ModalService } from '@dougs/ds';
import { EcommerceStateService } from '@dougs/ecommerce/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { FileStateService } from '@dougs/settings/shared';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { BillingInvoiceStateService, SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { SalesViaPlatformInfoModalComponent } from '../modals/sales-via-platform-info-modal/sales-via-platform-info-modal.component';
import { individualSalesValidator } from '../validators';
import { isDate } from '../validators/date.validator';

const ECOMMERCE_FIELDS_SCHEMA: Record<string, any>[] = [
  {
    salesViaPlatformConfiguration: [
      'locked',
      'dateLocked',
      'salesViaPlatform',
      'platformCommission',
      'individualEuropeanSales',
      'dropShippingOver150',
      'dropShippingUnder150FromPersonalWeb',
      'dropShippingUnder150FromMarketPlace',
      'individualSalesFromFrenchStock',
      'individualSalesFromUeStock',
      'individualServicesEuSales',
      'euIndividualRevenueOver10000',
      'forceOssWithOption',
      'ossActivationDate',
      'iossActivationDate',
      'ecommerceExtensionDeactivated',
    ],
  },
];

@Injectable()
export class EcommerceFormService extends FormSettingsService {
  private originalPlan!: SubscriptionPlan | null;
  private readonly plans!: SubscriptionPlan[];
  private readonly _showValidateFormButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _showValidateIOSSButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly ecommerceStateService: EcommerceStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly fileStateService: FileStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    private readonly ownershipService: OwnershipService,
  ) {
    super(ECOMMERCE_FIELDS_SCHEMA);
    this.formGroup.addValidators(individualSalesValidator);
  }

  lockedValuesChanges$!: Observable<any> | undefined;
  lockedDateValuesChanges$!: Observable<any> | undefined;
  salesViaPlatformValuesChanges$!: Observable<any> | undefined;
  platformCommissionValuesChanges$!: Observable<any> | undefined;
  individualEuropeanSalesValuesChanges$!: Observable<any> | undefined;
  dropShippingOver150ValuesChanges$!: Observable<any> | undefined;
  dropShippingUnder150FromPersonalWebValuesChanges$!: Observable<any> | undefined;
  dropShippingUnder150FromMarketPlaceValuesChanges$!: Observable<any> | undefined;
  individualSalesFromFrenchStockValuesChanges$!: Observable<any> | undefined;
  individualSalesFromUeStockValuesChanges$!: Observable<any> | undefined;
  individualServicesEuSalesValuesChanges$!: Observable<any> | undefined;
  euIndividualRevenueOver10000ValuesChanges$!: Observable<any> | undefined;
  forceOssWithOptionValuesChanges$!: Observable<any> | undefined;
  ossActivationDateValuesChanges$!: Observable<any> | undefined;
  iossActivationDateValuesChanges$!: Observable<any> | undefined;
  ecommerceExtensionDeactivatedValuesChanges$!: Observable<boolean> | undefined;
  company!: Company;

  enableValidateLockDate$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.salesViaPlatformConfigurationGroup.valueChanges,
  ]).pipe(map(() => this.enableValidateLockDate()));
  showValidateFormButton$: Observable<boolean> = this._showValidateFormButtonSubject.asObservable();
  showValidateIOSSButton$: Observable<boolean> = this._showValidateIOSSButtonSubject.asObservable();

  ossIossInfoText$: Observable<string> = this.companyStateService.activeCompany$.pipe(
    map((activeCompany) => {
      if (activeCompany.accountingConfiguration.hasOss && activeCompany.accountingConfiguration.hasIoss) {
        return 'OSS/IOSS';
      } else if (activeCompany.accountingConfiguration.hasOss) {
        return 'OSS';
      }
      return 'IOSS';
    }),
  );

  get salesViaPlatformConfigurationGroup(): UntypedFormGroup {
    return this.formGroup.get('salesViaPlatformConfiguration') as UntypedFormGroup;
  }

  public formatForm(company: Company): void {
    if (!this.company || this.company.id !== company.id) {
      this.company = company;
      void this.setOriginalPlan(); // avoid blocking on this setter (used only on form submission)
    }

    this._showValidateFormButtonSubject.next(!company.salesViaPlatformConfiguration.locked);
    this._showValidateIOSSButtonSubject.next(!company.salesViaPlatformConfiguration.dateLocked);

    ECOMMERCE_FIELDS_SCHEMA[0]?.['salesViaPlatformConfiguration'].forEach((key: string) => {
      if (
        company.salesViaPlatformConfiguration.locked &&
        key !== 'ossActivationDate' &&
        key !== 'iossActivationDate' &&
        key !== 'locked' &&
        key !== 'dateLocked' &&
        key !== 'ecommerceExtensionDeactivated'
      ) {
        this.salesViaPlatformConfigurationGroup?.get(key)?.disable({ emitEvent: false });
      } else if (
        company.salesViaPlatformConfiguration.dateLocked &&
        (key === 'ossActivationDate' || key === 'iossActivationDate')
      ) {
        this.salesViaPlatformConfigurationGroup?.get(key)?.disable({ emitEvent: false });
      } else if (!company.salesViaPlatformConfiguration.salesViaPlatform && key === 'ecommerceExtensionDeactivated') {
        this.salesViaPlatformConfigurationGroup?.get(key)?.disable({ emitEvent: false });
      } else {
        this.salesViaPlatformConfigurationGroup?.get(key)?.enable({ emitEvent: false });
      }
    });

    if (company.salesViaPlatformConfiguration.hasOss && company.salesViaPlatformConfiguration.locked) {
      this.salesViaPlatformConfigurationGroup?.get('ossActivationDate')?.setValidators(isDate());
    } else {
      this.salesViaPlatformConfigurationGroup?.get('ossActivationDate')?.setValidators(null);
    }

    if (company.salesViaPlatformConfiguration.hasIoss && company.salesViaPlatformConfiguration.locked) {
      this.salesViaPlatformConfigurationGroup?.get('iossActivationDate')?.setValidators(isDate());
    } else {
      this.salesViaPlatformConfigurationGroup?.get('iossActivationDate')?.setValidators(null);
    }
  }

  public populateForm(company: Company): void {
    this.populateFieldsForm({
      ...company,
      salesViaPlatformConfiguration: {
        ...company.salesViaPlatformConfiguration,
        ecommerceExtensionDeactivated: !company.flags.includes('noPackOptions'),
      },
    });

    if (company.salesViaPlatformConfiguration.ossActivationDate) {
      this.salesViaPlatformConfigurationGroup
        ?.get('ossActivationDate')
        ?.setValue(company.salesViaPlatformConfiguration.ossActivationDate, {
          emitEvent: false,
        });
    }

    if (company.salesViaPlatformConfiguration.iossActivationDate) {
      this.salesViaPlatformConfigurationGroup
        ?.get('iossActivationDate')
        ?.setValue(company.salesViaPlatformConfiguration.iossActivationDate, {
          emitEvent: false,
        });
    }

    this.listenFormGroupChanges(company);
  }

  public listenFormGroupChanges(company: Company): void {
    this.lockedValuesChanges$ = this.listenOnFormControl('locked', (locked) => {
      this._showValidateFormButtonSubject.next(!locked);
      return from(
        locked
          ? this.ecommerceStateService.lockSalesViaPlatformConfiguration(company)
          : this.ecommerceStateService.unlockSalesViaPlatformConfiguration(company),
      );
    });

    this.ecommerceExtensionDeactivatedValuesChanges$ = this.listenOnFormControl(
      'ecommerceExtensionDeactivated',
      (deactivated: unknown) =>
        from(
          deactivated
            ? this.ecommerceStateService.activateEcommerceExtension(company)
            : this.ecommerceStateService.deactivateEcommerceExtension(company),
        ),
    );

    this.lockedDateValuesChanges$ = this.listenOnFormControl('dateLocked', (dateLocked) => {
      this._showValidateIOSSButtonSubject.next(!dateLocked);
      return from(
        dateLocked
          ? this.ecommerceStateService.lockDateSalesViaPlatformConfiguration(company)
          : this.ecommerceStateService.unlockDateSalesViaPlatformConfiguration(company),
      );
    });

    this.salesViaPlatformValuesChanges$ = this.listenOnFormControl('salesViaPlatform', (salesViaPlatform) =>
      from(
        salesViaPlatform
          ? this.ecommerceStateService.enableSalesViaPlatform(company)
          : this.ecommerceStateService.disableSalesViaPlatform(company),
      ),
    );

    this.platformCommissionValuesChanges$ = this.listenOnFormControl('platformCommission', (platformCommission) =>
      from(
        platformCommission
          ? this.ecommerceStateService.enablePlatformCommission(company)
          : this.ecommerceStateService.disablePlatformCommission(company),
      ),
    );

    this.individualEuropeanSalesValuesChanges$ = this.listenOnFormControl(
      'individualEuropeanSales',
      (individualEuropeanSales) =>
        from(
          individualEuropeanSales
            ? this.ecommerceStateService.enableIndividualEuropeanSales(company)
            : this.ecommerceStateService.disableIndividualEuropeanSales(company),
        ),
    );

    this.dropShippingOver150ValuesChanges$ = this.listenOnFormControl('dropShippingOver150', (dropShippingOver150) =>
      from(
        dropShippingOver150
          ? this.ecommerceStateService.enableDropShippingOver150(company)
          : this.ecommerceStateService.disableDropShippingOver150(company),
      ),
    );

    this.dropShippingUnder150FromPersonalWebValuesChanges$ = this.listenOnFormControl(
      'dropShippingUnder150FromPersonalWeb',
      (dropShippingUnder150FromPersonalWeb) =>
        from(
          dropShippingUnder150FromPersonalWeb
            ? this.ecommerceStateService.enableDropShippingUnder150FromPersonalWeb(company)
            : this.ecommerceStateService.disableDropShippingUnder150FromPersonalWeb(company),
        ),
    );

    this.dropShippingUnder150FromMarketPlaceValuesChanges$ = this.listenOnFormControl(
      'dropShippingUnder150FromMarketPlace',
      (dropShippingUnder150FromMarketPlace) =>
        from(
          dropShippingUnder150FromMarketPlace
            ? this.ecommerceStateService.enableDropShippingUnder150FromMarketPlace(company)
            : this.ecommerceStateService.disableDropShippingUnder150FromMarketPlace(company),
        ),
    );

    this.individualSalesFromFrenchStockValuesChanges$ = this.listenOnFormControl(
      'individualSalesFromFrenchStock',
      (individualSalesFromFrenchStock) =>
        from(
          individualSalesFromFrenchStock
            ? this.ecommerceStateService.enableIndividualSalesFromFrenchStock(company)
            : this.ecommerceStateService.disableIndividualSalesFromFrenchStock(company),
        ),
    );

    this.individualSalesFromUeStockValuesChanges$ = this.listenOnFormControl(
      'individualSalesFromUeStock',
      (individualSalesFromUeStock) =>
        from(
          individualSalesFromUeStock
            ? this.ecommerceStateService.enableIndividualSalesFromUeStock(company)
            : this.ecommerceStateService.disableIndividualSalesFromUeStock(company),
        ),
    );

    this.individualServicesEuSalesValuesChanges$ = this.listenOnFormControl(
      'individualServicesEuSales',
      (individualServicesEuSales) =>
        from(
          individualServicesEuSales
            ? this.ecommerceStateService.enableIndividualServicesEuSales(company)
            : this.ecommerceStateService.disableIndividualServicesEuSales(company),
        ),
    );

    this.euIndividualRevenueOver10000ValuesChanges$ = this.listenOnFormControl(
      'euIndividualRevenueOver10000',
      (euIndividualRevenueOver10000) =>
        from(
          euIndividualRevenueOver10000
            ? this.ecommerceStateService.enableEuIndividualRevenueOver10000(company)
            : this.ecommerceStateService.disableEuIndividualRevenueOver10000(company),
        ),
    );

    this.forceOssWithOptionValuesChanges$ = this.listenOnFormControl('forceOssWithOption', (forceOssWithOption) =>
      from(
        forceOssWithOption
          ? this.ecommerceStateService.enableForceOssWithOption(company)
          : this.ecommerceStateService.disableForceOssWithOption(company),
      ),
    );
  }

  async uploadOssFiles(company: Company, files: FileList): Promise<void> {
    await this.fileStateService.uploadAttachments(company, files, 'ossInscriptions', 'ossInscription');
  }

  async deleteOssFile(company: Company, attachment: Attachment): Promise<void> {
    await this.fileStateService.deleteAttachments(company, attachment, 'ossInscriptions', true);
  }

  async uploadIOssFiles(company: Company, files: FileList): Promise<void> {
    await this.fileStateService.uploadAttachments(company, files, 'iossInscriptions', 'iossInscription');
  }

  async deleteIOssFile(company: Company, attachment: Attachment): Promise<void> {
    await this.fileStateService.deleteAttachments(company, attachment, 'iossInscriptions', true);
  }

  private listenOnFormControl(
    formControlName: string,
    callbackValueChanges: (value: unknown) => Observable<unknown>,
  ): Observable<any> | undefined {
    return this.formGroup
      .get('salesViaPlatformConfiguration')
      ?.get(formControlName)
      ?.valueChanges.pipe(distinctUntilChanged(), concatMap(callbackValueChanges));
  }

  async onSubmit(company: Company, shouldShowValidateEcommerceSettings: boolean): Promise<void> {
    if (!this.validateForm()) {
      const individualSalesError: string | null =
        this.salesViaPlatformConfigurationGroup.getError('individualSalesError');
      if (individualSalesError) {
        this.flashMessagesService.show(individualSalesError, {
          type: 'error',
          timeout: 5000,
        });
      }
      return;
    }

    if (this.enableValidateLockDate()) {
      let canLockDate = true;
      if (this.company.salesViaPlatformConfiguration.hasOss) {
        canLockDate = await this.ecommerceStateService.updateOssActivationDate(
          company,
          this.salesViaPlatformConfigurationGroup.get('ossActivationDate')?.value,
        );
      }

      if (this.company.salesViaPlatformConfiguration.hasIoss) {
        canLockDate = await this.ecommerceStateService.updateIOssActivationDate(
          company,
          this.salesViaPlatformConfigurationGroup.get('iossActivationDate')?.value,
        );
      }

      if (canLockDate) {
        await this.ecommerceStateService.lockDateSalesViaPlatformConfiguration(company);
      }
    }

    if (shouldShowValidateEcommerceSettings) {
      const newPlan: SubscriptionPlan | null =
        await this.ecommerceStateService.lockSalesViaPlatformConfiguration(company);
      if (newPlan) {
        const newPlanWithAdjustment: SubscriptionPlan | null =
          await this.subscriptionStateService.checkDetailsUpcommingInvoice(company.id, newPlan);
        if (newPlanWithAdjustment && newPlanWithAdjustment.adjustment) {
          newPlan.adjustment = newPlanWithAdjustment.adjustment;
        }
      }

      await lastValueFrom(
        this.modalService.open(SalesViaPlatformInfoModalComponent, {
          data: {
            salesViaPlatformConfiguration: company.salesViaPlatformConfiguration,
            originalPlan: this.originalPlan,
            newPlan,
          },
        }).afterClosed$,
      );
      if (newPlan) {
        await this.companyStateService.refreshCompany();
        await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
        await this.companyServicesStateService.refreshServices(this.companyStateService.activeCompany.id);
        await this.billingInvoiceStateService.refreshPendingBillingInvoice(this.companyStateService.activeCompany.id);
        if (this.ownershipService.isMyCompany()) {
          this.subscriptionStateService.openAcceptSubscriptionPlanPoliciesModal.next();
        }
      }
    }

    this.formGroup.markAsPristine();
  }

  enableValidateLockDate(): boolean {
    if (!this.company) {
      return false;
    }

    if (this.company.salesViaPlatformConfiguration.dateLocked) {
      return false;
    }

    if (
      this.company.salesViaPlatformConfiguration.hasOss &&
      (!this.salesViaPlatformConfigurationGroup.get('ossActivationDate')?.value ||
        !this.salesViaPlatformConfigurationGroup.get('ossActivationDate')?.valid ||
        this.company.ossInscriptions?.length === 0)
    ) {
      return false;
    }

    if (
      this.company.salesViaPlatformConfiguration.hasIoss &&
      (!this.salesViaPlatformConfigurationGroup.get('iossActivationDate')?.value ||
        !this.salesViaPlatformConfigurationGroup.get('iossActivationDate')?.valid ||
        this.company.iossInscriptions?.length === 0)
    ) {
      return false;
    }

    return !(!this.company.salesViaPlatformConfiguration.hasOss && !this.company.salesViaPlatformConfiguration.hasIoss);
  }

  openIndividualServicesInfoModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'Vente de services électroniques',
        body:
          'La "vente de services électroniques" désigne la fourniture de services par des moyens électroniques, souvent via Internet.<br>' +
          'Cela inclut des activités comme la diffusion de logiciels, les abonnements à des plateformes de streaming, les cours en ligne, le stockage de données sur le cloud, et les services de consultation en ligne.<br>' +
          "En bref, il s'agit de tout service qui est acheté, diffusé et utilisé de manière numérique.",
      },
    });
  }

  openOSSInfoModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'OSS',
        body:
          'One-Stop-Shop (OSS) : système de déclaration de TVA européen, concernant :' +
          '<ul>' +
          "<li>la prestation de services fournies à des particuliers au sein de l'Union Européenne</li>" +
          "<li>la vente à distance de biens à des particuliers au sein de l'Union Européenne</li>" +
          '</ul>',
      },
    });
  }

  openIOSSInfoModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'IOSS',
        body: "Import One-Stop-Shop (IOSS) : système de déclaration de TVA européen, concernant la vente à distance de biens à des particuliers au sein de l'Union Européenne</li>",
      },
    });
  }

  private async setOriginalPlan(): Promise<void> {
    if (!this.company.subscription?.plan) {
      this.originalPlan = null;
    } else {
      try {
        this.originalPlan = this.plans.find((p) => p.id === this.company.subscription?.plan) || null;
      } catch (e) {
        this.originalPlan = null;
      }
    }
  }
}
