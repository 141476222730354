import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { isAfter } from 'date-fns';
import { BehaviorSubject, combineLatest, filter, from, lastValueFrom, Observable, of, Subject } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ScrollAccordionService } from '@dougs/core/scroll-accordion';
import { toPromise } from '@dougs/core/utils';
import { ConfirmationModalComponent, FlashMessagesService, HelpModalComponent, ModalService } from '@dougs/ds';
import {
  EcommerceShopSyncModalComponentData,
  MarketPlaceConfiguration,
  PaymentsProcessorWithDate,
  SalesChannel,
  SalesChannelCategory,
  SalesChannelPost,
  ShopifyAuthenticatedSession,
} from '@dougs/ecommerce/dto';
import {
  canSynchronizeShop,
  ecommerceShopSyncConfig,
  isShopSynchronizable,
  SalesChannelStateService,
  ShopifyStateService,
} from '@dougs/ecommerce/shared';
import { AddSalesChannelModalComponent } from '../modals/add-sales-channel-amazon-modal/add-sales-channel-amazon-modal.component';
import { EcommerceShopSyncModalComponent } from '../modals/ecommerce-shop-sync-modal/ecommerce-shop-sync-modal.component';
import { EcommerceSynchronizedShopModalComponent } from '../modals/ecommerce-synchronized-shop-modal/ecommerce-synchronized-shop-modal.component';
import { SalesChannelListService } from '../services/sales-channel-list.service';
import { shouldHaveOneStock, shouldSellSomething } from '../validators';
import { shouldHaveOnePaymentProcessor } from '../validators/should-have-one-payment-processor';

export interface EcommerceSalesChannelFormData {
  category: FormControl<SalesChannelCategory | null>;
  type: FormControl<string | null>;
  memo: FormControl<string | null>;
  paymentsProcessorsWithDate: FormArray<FormGroup<EcommercePaymentsProcessorWithDate>>;
  sellsMerchandise: FormControl<boolean | null>;
  sellsFinishedProduct: FormControl<boolean | null>;
  salesServices: FormControl<boolean | null>;
  fromFrance: FormControl<boolean | null>;
  fromEu: FormControl<boolean | null>;
  fromOutsideEu: FormControl<boolean | null>;
  endDate: FormControl<Date | null>;
  shopUrl: FormControl<string | null>;
  synchronizeShop: FormControl<boolean>;
}

export interface EcommercePaymentsProcessorWithDate {
  id: FormControl<number | undefined | null>;
  endDate: FormControl<Date | undefined | null>;
  paymentsProcessorId: FormControl<number | null | undefined>;
}

@Injectable()
export class EcommerceSalesChannelFormService {
  constructor(
    public readonly salesChannelStateService: SalesChannelStateService,
    private readonly salesChannelListService: SalesChannelListService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly scrollAccordionService: ScrollAccordionService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly shopifyState: ShopifyStateService,
    private readonly metricsService: MetricsService,
  ) {}

  private readonly isLoading: WritableSignal<boolean> = signal<boolean>(false);
  private readonly showPaymentProcessors: WritableSignal<boolean> = signal<boolean>(false);
  private readonly shopTypeLabel: WritableSignal<string> = signal<string>('');
  private readonly showSyncForm: WritableSignal<boolean> = signal<boolean>(false);
  private readonly formGroupHasBeenTouched: Subject<void> = new Subject<void>();
  private readonly salesChannelSubject: BehaviorSubject<SalesChannel | undefined> = new BehaviorSubject<
    SalesChannel | undefined
  >(undefined);

  isLoading$: Signal<boolean> = this.isLoading.asReadonly();
  showPaymentProcessors$: Signal<boolean> = this.showPaymentProcessors.asReadonly();
  shopTypeLabel$: Signal<string> = this.shopTypeLabel.asReadonly();
  showSyncForm$: Signal<boolean> = this.showSyncForm.asReadonly();

  formGroup: FormGroup<EcommerceSalesChannelFormData> = new FormGroup<EcommerceSalesChannelFormData>(
    {
      category: new FormControl<SalesChannelCategory | null>(null, Validators.required),
      type: new FormControl<string | null>(null),
      memo: new FormControl<string | null>(null, Validators.required),
      paymentsProcessorsWithDate: new FormArray<FormGroup<EcommercePaymentsProcessorWithDate>>([], {
        validators: [shouldHaveOnePaymentProcessor()],
      }),
      sellsMerchandise: new FormControl<boolean | null>(null, Validators.required),
      sellsFinishedProduct: new FormControl<boolean | null>(null, Validators.required),
      salesServices: new FormControl<boolean | null>(null, Validators.required),
      fromFrance: new FormControl<boolean | null>(null),
      fromEu: new FormControl<boolean | null>(null),
      fromOutsideEu: new FormControl<boolean | null>(null),
      endDate: new FormControl<Date | null>({ value: null, disabled: true }),
      shopUrl: new FormControl<string | null>({ value: null, disabled: true }, Validators.required),
      synchronizeShop: new FormControl<boolean>(false, { nonNullable: true }),
    },
    { validators: [shouldSellSomething(), shouldHaveOneStock()] },
  );

  async setSalesChannel(salesChannel: SalesChannel | undefined): Promise<void> {
    this.salesChannelSubject.next(salesChannel);

    if (salesChannel) {
      await this.mapSalesChannelToForm(salesChannel);
    }
  }

  formGroupHasBeenTouched$: Observable<void> = this.formGroupHasBeenTouched.asObservable();

  salesChannel$: Observable<SalesChannel | undefined> = this.salesChannelSubject.asObservable();

  onCategoryChanges$: Observable<void> = this.formGroup.controls.category.valueChanges.pipe(
    map(() => {
      this.resetTypeControl();
      this.formatTypeControl();
      this.formatPaymentsProcessorWithDateControl();
      this.formGroupHasBeenTouched.next();
    }),
  );

  isShopSynchronized$: Observable<boolean> = this.salesChannel$.pipe(
    concatMap((salesChannel: SalesChannel | undefined) => {
      if (salesChannel?.id && salesChannel?.companyId) {
        return from(this.shopifyState.isAuthenticated(salesChannel.companyId, salesChannel.id)).pipe(
          map((session: ShopifyAuthenticatedSession) => session.isAuthenticated),
        );
      }
      return of(false);
    }),
  );

  isSynchronizable$: Observable<void> = combineLatest([
    this.formGroup.controls.type.valueChanges,
    this.companyStateService.activeCompany$,
  ]).pipe(map(([type, company]) => this.setShowSyncForm(type || '', company)));

  mustIndicateStock$: Observable<void> = combineLatest([
    this.formGroup.controls.sellsMerchandise.valueChanges,
    this.formGroup.controls.sellsFinishedProduct.valueChanges,
  ]).pipe(
    map(([sellsMerchandise, sellsFinishedProduct]) => {
      if (sellsMerchandise || sellsFinishedProduct) {
        this.formGroup.controls.fromFrance.setValidators(Validators.required);
        this.formGroup.controls.fromEu.setValidators(Validators.required);
        this.formGroup.controls.fromOutsideEu.setValidators(Validators.required);
      } else {
        this.formGroup.controls.fromFrance.clearValidators();
        this.formGroup.controls.fromEu.clearValidators();
        this.formGroup.controls.fromOutsideEu.clearValidators();
      }
      this.formGroup.controls.fromFrance.updateValueAndValidity();
      this.formGroup.controls.fromEu.updateValueAndValidity();
      this.formGroup.controls.fromOutsideEu.updateValueAndValidity();
    }),
  );

  togglePaymentsProcessor$: Observable<void> = combineLatest([
    this.formGroup.controls.type.valueChanges,
    this.formGroup.controls.category.valueChanges,
    this.salesChannelStateService.marketPlacesConfigurationListWithoutDisabled$,
  ]).pipe(
    map(([type, category, marketPlacesConfigurationList]) =>
      this.togglePaymentsProcessor(marketPlacesConfigurationList, type, category),
    ),
  );

  alreadyHaveOneAmazonEu$: Observable<boolean> = combineLatest([
    this.formGroup.controls.type.valueChanges,
    this.salesChannelStateService.salesChannels$,
  ]).pipe(
    map(([type, salesChannels]) => {
      return (
        type === 'amazoneu' &&
        salesChannels.some(
          (salesChannel) =>
            salesChannel.type === 'amazoneu' &&
            (!salesChannel.endDate || isAfter(new Date(salesChannel.endDate), new Date())),
        )
      );
    }),
  );

  disableControlsOnEdition$: Observable<void> = this.salesChannelListService.isCreating$.pipe(
    filter((isCreating) => !isCreating),
    map(() => {
      this.formGroup.controls.category.disable({ emitEvent: false });
      this.formGroup.controls.type.disable({ emitEvent: false });
    }),
  );

  toggleShopUrl$: Observable<void> = combineLatest([
    this.formGroup.controls.type.valueChanges,
    this.isShopSynchronized$,
    this.companyStateService.activeCompany$,
  ]).pipe(map(([type, isSynchronized, company]) => this.toggleShopUrl(type, isSynchronized, company)));

  toggleEndDate$: Observable<void> = this.formGroup.controls.type.valueChanges.pipe(
    map((type) => this.toggleEndDate(type)),
  );

  updateShopTypeLabel$: Observable<void> = this.formGroup.controls.type.valueChanges.pipe(
    filter((type): type is string => !!type),
    map((type) => this.setShopTypeLabel(type)),
  );

  addPaymentsProcessorWithDate(): void {
    const formGroup: FormGroup<EcommercePaymentsProcessorWithDate> = this.getPaymentProcessorFormGroup();
    if (this.formGroup.controls.paymentsProcessorsWithDate?.touched) {
      formGroup.markAllAsTouched();
    }
    this.formGroup.controls.paymentsProcessorsWithDate.push(formGroup);
  }

  deletePaymentsProcessorWithDate(index: number): void {
    this.formGroup.controls.paymentsProcessorsWithDate.removeAt(index);
  }

  openMissingPlatformModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'Plateforme utilisée pour votre canal de vente',
        body: `La plateforme que vous utilisez n'est pas dans la liste ?`,
        contactUsMessage: 'Contactez-nous',
      },
    });
  }

  async onSubmit(salesChannel?: SalesChannel, fromModal = false): Promise<SalesChannel | null> {
    if (this.isFormValid() && !this.isLoading()) {
      this.isLoading.set(true);

      if (!this.formGroup.controls.sellsMerchandise?.value && !this.formGroup.controls.sellsFinishedProduct?.value) {
        this.setStockToFalse();
      }

      const salesChannelReturned: SalesChannel | null = await this.saveSaleChannel(salesChannel);

      this.isLoading.set(false);
      this.salesChannelListService.setEditedSalesChannelId();
      this.scrollAccordionService.scrollToId('sales-channel-' + (salesChannel?.id || 'content'));

      if (
        await this.shouldOpenShopSyncModal(
          fromModal,
          salesChannelReturned,
          this.formGroup.controls.synchronizeShop.value,
        )
      ) {
        setTimeout(async () => {
          const isSynchronized = (
            await toPromise(
              this.modalService.open<boolean, EcommerceShopSyncModalComponentData>(EcommerceShopSyncModalComponent, {
                data: { salesChannel: salesChannelReturned as SalesChannel, origin: 'settings' },
              }).afterClosed$,
            )
          ).data;

          if (isSynchronized && salesChannelReturned?.type !== 'shopify') {
            this.modalService.open(EcommerceSynchronizedShopModalComponent, {
              data: { shopUrl: salesChannelReturned?.shopUrl, shopType: salesChannelReturned?.type },
            });
          }
        }, 1000);
      }

      if (
        await this.shouldOpenShopifyUnsyncModal(salesChannelReturned, this.formGroup.controls.synchronizeShop.value)
      ) {
        setTimeout(() => {
          void this.openUninstallShopifyModal(salesChannelReturned as SalesChannel);
        }, 1000);
      }

      if (!salesChannel && salesChannelReturned && salesChannelReturned.type === 'amazoneu') {
        await lastValueFrom(
          this.modalService.open(AddSalesChannelModalComponent, {
            data: {
              salesChannel: salesChannelReturned,
            },
          }).afterClosed$,
        );
      }

      return salesChannelReturned;
    }
    if (!this.isFormValid()) {
      this.flashMessagesService.show(`Les champs obligatoires ne sont pas tous remplis`, {
        type: 'error',
      });
    }
    return null;
  }

  getPaymentsProcessorIdControlAtIndex(index: number): AbstractControl | null {
    return this.formGroup.controls.paymentsProcessorsWithDate.at(index).get('paymentsProcessorId');
  }

  getEndDateControlAtIndex(index: number): AbstractControl | null {
    return this.formGroup.controls.paymentsProcessorsWithDate.at(index).get('endDate');
  }

  sendSyncInfoModalMetrics(salesChannel?: SalesChannel | null): void {
    this.metricsService.pushMixpanelEvent('e-Commerce sales channel synchronization helper', {
      'Channel Category': salesChannel?.category || this.formGroup.controls.category.value,
      'Channel Type': salesChannel?.type || this.formGroup.controls.type.value,
      'Payment processors associated':
        salesChannel?.paymentsProcessorsWithDate || this.formGroup.controls.paymentsProcessorsWithDate.value,
      'Sells Merchandise': salesChannel?.sellsMerchandise || this.formGroup.controls.sellsMerchandise.value,
      'Sells Finished products':
        salesChannel?.sellsFinishedProduct || this.formGroup.controls.sellsFinishedProduct.value,
      'Sells Services': salesChannel?.salesServices || this.formGroup.controls.salesServices.value,
      'Has Stock in France': salesChannel?.fromFrance || this.formGroup.controls.fromFrance.value,
      'Has Stock in Europe': salesChannel?.fromEu || this.formGroup.controls.fromEu.value,
      'Does DropShipping': salesChannel?.fromOutsideEu || this.formGroup.controls.fromOutsideEu.value,
      isCreated: !!salesChannel,
    });
  }

  private isFormValid(): boolean {
    this.formGroup.markAllAsTouched();
    this.formGroupHasBeenTouched.next();
    return this.formGroup.valid;
  }

  private async shouldOpenShopSyncModal(
    fromModal: boolean,
    salesChannelReturned: SalesChannel | null,
    wantToSynchronize: boolean,
  ): Promise<boolean> {
    if (salesChannelReturned && wantToSynchronize && isShopSynchronizable(this.formGroup.controls.type.value || '')) {
      // TODO adapt
      const session: ShopifyAuthenticatedSession = await this.shopifyState.isAuthenticated(
        salesChannelReturned.companyId,
        salesChannelReturned.id,
      );
      return !session.isAuthenticated && !fromModal;
    }

    return false;
  }

  private async shouldOpenShopifyUnsyncModal(
    salesChannel: SalesChannel | null,
    wantToSynchronize: boolean,
  ): Promise<boolean> {
    return !!salesChannel && (await toPromise(this.isShopSynchronized$)) && !wantToSynchronize;
  }

  private formatPaymentsProcessorWithDateControl(): void {
    if (this.formGroup.controls.category.value !== SalesChannelCategory.WEBSITE) {
      this.formGroup.controls.paymentsProcessorsWithDate?.disable();
    } else {
      this.formGroup.controls.paymentsProcessorsWithDate?.enable();
    }
  }

  private resetTypeControl(): void {
    this.formGroup.controls.type.setValue(null);
  }

  private formatTypeControl(): void {
    this.formGroup.controls.paymentsProcessorsWithDate?.clear();
    if (this.formGroup.controls.category.value !== SalesChannelCategory.PHYSICAL) {
      this.formGroup.controls.type.setValidators(Validators.required);
    } else {
      this.formGroup.controls.type.clearValidators();
    }
  }

  private async openUninstallShopifyModal(salesChannel: SalesChannel): Promise<void> {
    const shouldUninstall: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService.open<boolean>(ConfirmationModalComponent, {
          data: {
            title: 'Désynchroniser ma boutique Shopify',
            body: `Nous ne préremplirons plus la ventilation <span class="italic">${salesChannel.name}</span> et ne conserverons aucune donnée issue de votre boutique. Vous pouvez la resynchroniser à tout moment.`,
            yesText: 'Désynchroniser ma boutique',
            noText: 'Annuler',
          },
        }).afterClosed$,
      )
    ).data;

    if (shouldUninstall) {
      await this.salesChannelStateService.deleteAuth(salesChannel);
      this.flashMessagesService.show(`La désynchronisation a été effectuée`, {
        type: 'success',
      });
    }
  }

  private async saveSaleChannel(salesChannel?: SalesChannel): Promise<SalesChannel | null> {
    if (salesChannel) {
      return await this.salesChannelStateService.updateSalesChannel(this.companyStateService.activeCompany, {
        id: `${salesChannel.id}`,
        ...this.mapFormToSalesChannelPost(salesChannel),
      });
    }
    this.salesChannelListService.isCreating = false;
    return await this.salesChannelStateService.createSalesChannel(
      this.companyStateService.activeCompany,
      this.mapFormToSalesChannelPost(),
    );
  }

  private setStockToFalse(): void {
    this.formGroup.controls.fromFrance.setValue(false);
    this.formGroup.controls.fromEu.setValue(false);
    this.formGroup.controls.fromOutsideEu.setValue(false);
  }

  private togglePaymentsProcessor(
    marketPlacesConfigurationList: MarketPlaceConfiguration[] | undefined,
    type: string | null,
    category: string | null,
  ): void {
    if (!marketPlacesConfigurationList) {
      this.showPaymentProcessors.set(false);
      return;
    }
    const marketplace: MarketPlaceConfiguration | undefined = marketPlacesConfigurationList.find(
      (mp) => mp.value === type,
    );
    this.showPaymentProcessors.set(
      category === SalesChannelCategory.WEBSITE ||
        category === SalesChannelCategory.PHYSICAL ||
        (category === SalesChannelCategory.MARKETPLACE && !!marketplace?.allowCustomPaymentsProcessor),
    );
  }

  private toggleShopUrl(type: string | null, isSynchronized: boolean, company: Company): void {
    if (type && canSynchronizeShop(type, company) && !isSynchronized) {
      this.formGroup.controls.shopUrl.enable();
    } else {
      this.formGroup.controls.shopUrl.disable();
    }
  }

  private toggleEndDate(type: string | null): void {
    if (type && isShopSynchronizable(type || '')) {
      this.formGroup.controls.endDate.enable();
    } else {
      this.formGroup.controls.endDate.disable();
    }
  }

  private async mapSalesChannelToForm(salesChannel: SalesChannel): Promise<void> {
    const isShopSynchronized: boolean = await toPromise(this.isShopSynchronized$);
    const company: Company = await toPromise(this.companyStateService.activeCompany$);

    this.formGroup.reset();
    this.formGroup.patchValue({
      category: salesChannel.category,
      type: salesChannel.type,
      memo: salesChannel.memo || null,
      sellsMerchandise: salesChannel.sellsMerchandise,
      sellsFinishedProduct: salesChannel.sellsFinishedProduct,
      salesServices: salesChannel.salesServices,
      fromFrance: salesChannel.fromFrance,
      fromEu: salesChannel.fromEu,
      fromOutsideEu: salesChannel.fromOutsideEu,
      endDate: salesChannel.endDate || null,
      shopUrl: salesChannel.shopUrl || null,
      synchronizeShop: isShopSynchronized,
    });
    this.togglePaymentsProcessor(
      await toPromise(this.salesChannelStateService.marketPlacesConfigurationListWithoutDisabled$),
      salesChannel.type,
      salesChannel.category,
    );
    this.toggleShopUrl(salesChannel.type, isShopSynchronized, company);
    this.toggleEndDate(salesChannel.type);
    this.setShopTypeLabel(salesChannel.type);
    this.setShowSyncForm(salesChannel.type, company);

    salesChannel.paymentsProcessorsWithDate?.forEach((paymentsProcessorWithDate) => {
      this.formGroup.controls.paymentsProcessorsWithDate.push(
        this.getPaymentProcessorFormGroup(paymentsProcessorWithDate),
      );
    });

    if (!salesChannel.endDate) {
      this.formGroup.controls.endDate.disable();
    } else {
      this.formGroup.controls.endDate.enable();
    }
  }

  private getPaymentProcessorFormGroup(
    paymentsProcessorWithDate?: PaymentsProcessorWithDate,
  ): FormGroup<EcommercePaymentsProcessorWithDate> {
    return new FormGroup<EcommercePaymentsProcessorWithDate>({
      id: new FormControl<number | null>(paymentsProcessorWithDate?.id || null),
      endDate: new FormControl<Date | null>({
        value: paymentsProcessorWithDate?.endDate || null,
        disabled: !paymentsProcessorWithDate?.endDate,
      }),
      paymentsProcessorId: new FormControl<number | null>(
        paymentsProcessorWithDate?.paymentsProcessor?.id || null,
        Validators.required,
      ),
    });
  }

  private mapFormToSalesChannelPost(salesChannel?: SalesChannel): SalesChannelPost {
    const formValue = this.formGroup.getRawValue();

    return {
      id: salesChannel?.id.toString(),
      category: formValue.category || '',
      type: formValue.type || '',
      memo: formValue.memo || '',
      sellsMerchandise: !!formValue.sellsMerchandise,
      sellsFinishedProduct: !!formValue.sellsFinishedProduct,
      salesServices: !!formValue.salesServices,
      fromFrance: !!formValue.fromFrance,
      fromEu: !!formValue.fromEu,
      fromOutsideEu: !!formValue.fromOutsideEu,
      paymentsProcessorsWithDate: formValue.paymentsProcessorsWithDate.map((paymentProcessor) => ({
        id: paymentProcessor.id || undefined,
        endDate: paymentProcessor.endDate || null,
        paymentsProcessorId: paymentProcessor.paymentsProcessorId as number,
      })),
      endDate: formValue.endDate || null,
      shopUrl: formValue.shopUrl || '',
    };
  }

  private setShopTypeLabel(type: string): void {
    this.shopTypeLabel.set(ecommerceShopSyncConfig[type]?.shopTypeLabel || '');
  }

  private setShowSyncForm(type: string, company: Company): void {
    this.showSyncForm.set(canSynchronizeShop(type, company));
  }
}
