<div class="mt-16 mb-8" [formGroup]="ecommerceSalesChannelFormService.formGroup">
  <dougs-checkbox
    label="Votre site a fermé ?"
    class="mt-16"
    appearance="toggle"
    [showYesNo]="true"
    (click)="$event.stopPropagation()"
    [ngModel]="!!ecommerceSalesChannelFormService.formGroup.controls.endDate.value"
    (ngModelChange)="onNotUsedAnymoreChange($event)"
    [ngModelOptions]="{ standalone: true }"
  ></dougs-checkbox>
  <dougs-input-datepicker
    label="Date de fin d'utilisation"
    class="mt-16"
    formControlName="endDate"
    *ngIf="ecommerceSalesChannelFormService.formGroup.controls.endDate.enabled"
  ></dougs-input-datepicker>
</div>
