<div dougsModalTitle>Ajout du canal de vente {{ data.salesChannel.name }}</div>
<div dougsModalContent>
  <dougs-avatar-message
    class="mb-16"
    size="medium"
    fullName="Jean-Charles Morel"
    avatarUrl="https://static.intercomassets.com/avatars/3724593/square_128/jean-charles_full-1579506174.jpg"
  >
    <p class="small mb-16">
      Amazon oblige l'ensemble des vendeurs à s'enregistrer à l'OSS à des fins de reversement de la TVA dans les pays de
      destination où vous vendez.<br />
      <img class="mr-8" src="images/emoji/backhand-index-pointing-right.png" height="15" />
      <b>Vous devez renseigner votre numéro OSS dans les paramètres de votre Amazon Seller Central.</b>
    </p>

    <p class="small">
      Votre société doit avoir un numéro de TVA intracommunautaire dans chacun des pays de l'UE où vous détenez du
      stock. Pour ce faire, nous vous conseillons de vous rapprocher d'un représentant fiscal spécialisé dans les
      services d'Amazon.<br />
      <img class="mr-8" src="images/emoji/backhand-index-pointing-right.png" height="15" />
      <b
        >Vous devez renseigner ces numéros de TVA intracommunautaire dans les paramètres de votre Amazon Seller
        Central.</b
      >
    </p>
  </dougs-avatar-message>
</div>
<div dougsModalFooter>
  <dougs-button type="button" [dougsModalClose]="true">J'ai compris</dougs-button>
</div>
