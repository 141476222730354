<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.forceOssWithOptionValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Souhaitez-vous vous inscrire au guichet unique OSS-UE dès maintenant, sans attendre le dépassement du seuil de
        10 000 €&nbsp;?
        <div class="hint flex">
          <i class="fal fa-info-circle mr-8"></i>
          <span>
            Nous vous conseillons fortement de vous inscrire dès maintenant au guichet unique OSS-UE. Nous vous
            proposons le déclaration OSS. Dans le cas contraire, il vous faudra anticiper le dépassement du seuil en
            vous inscrivant au guichet unique le trimestre précédant la première vente concernée par un changement de
            situation, sous peine de devoir vous immatriculer dans tous les États membres où vous vendez.
          </span>
        </div>
      </label>
      <dougs-radio-group formControlName="forceOssWithOption" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
  </ng-container>
</ng-container>
