<div dougsModalTitle>
  <h6>Informations sur la nouvelle réglementation TVA</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-panel-info>
    <p class="small">
      Merci pour {{ salesViaPlatformConfiguration.salesViaPlatform ? 'votre' : 'vos' }} réponse{{
        salesViaPlatformConfiguration.salesViaPlatform ? '' : 's'
      }}.
    </p>
    <p class="small mt-8" *ngIf="newPlan">
      {{ buildNewPlanWordingFeatures() }}
    </p>
    <p class="small mt-8" *ngIf="!salesViaPlatformConfiguration.salesViaPlatform">
      Si vous ne faites pas de ventes à travers une plateforme en ligne, vous n'êtes pas concerné par les nouvelles
      règles de TVA en Union européenne.
      <br />
      Si vous souhaitez démarrer de la vente de services ou de marchandises en ligne prochainement, merci de nous
      contacter afin que nous puissions vous guider et vous garantir une comptabilité irréprochable.
    </p>
    <p
      class="small mt-8"
      *ngIf="salesViaPlatformConfiguration.salesViaPlatform && !salesViaPlatformConfiguration.individualEuropeanSales"
    >
      Vous n'êtes pas concerné par les nouvelles règles de TVA en Union européenne.
      <br />
      Si vous envisagez prochainement de développer vos ventes à des particuliers en Europe, merci de nous contacter
      pour vous assurer d'être en règle avec les nouvelles obligations de TVA.
    </p>
    <p class="small mt-8" *ngIf="salesViaPlatformConfiguration.individualEuropeanSales">
      Parce que vous faites de la vente à des particuliers en Union européenne (UE), vous avez des obligations fiscales
      concernant la TVA.
      <br />
      Veuillez lire attentivement ce qui suit !
    </p>
  </dougs-panel-info>
  <dougs-panel-info type="default" class="mt-8" *ngIf="salesViaPlatformConfiguration.hasOss">
    <p class="color-error small mb-8">
      ⚠️ IMPORTANT ⚠️ Afin de vous éviter une immatriculation dans tous les États membres où vous vendez :
    </p>
    <p class="small mb-8">
      Si ce n'est pas la cas, inscrivez-vous dès maintenant au guichet unique OSS-UE sur impots.gouv.fr !
    </p>
    <p class="small mb-8">
      Un bordereau d'inscription vous sera fourni : merci de le joindre à vos paramètres Dougs dès que possible.
    </p>
  </dougs-panel-info>
  <dougs-panel-info type="default" class="mt-8" *ngIf="salesViaPlatformConfiguration.hasIoss">
    <p class="color-error small mb-8">
      ⚠️ IMPORTANT ⚠️ Afin de vous éviter une immatriculation dans tous les États membres où vous vendez :
    </p>
    <p class="small mb-8">
      Si ce n'est pas la cas, inscrivez-vous dès maintenant au guichet unique IOSS sur impots.gouv.fr !
    </p>
    <p class="small mb-8">
      Un bordereau d'inscription vous sera fourni : merci de le joindre à vos paramètres Dougs dès que possible.
    </p>
  </dougs-panel-info>
  <dougs-panel-info
    type="default"
    class="mt-8"
    *ngIf="
      !isDropShipper &&
      salesViaPlatformConfiguration.individualEuropeanSales &&
      !salesViaPlatformConfiguration.hasOss &&
      !salesViaPlatformConfiguration.hasIoss
    "
  >
    <p class="small mb-8">
      <b>Vous êtes concerné par les nouvelles règles de TVA pour la vente intra-communautaire</b>
    </p>
    <p class="small mb-8">Vous déclarez faire de la vente BtoC en UE et remplir toutes les conditions ci-dessous :</p>
    <ul class="mb-8">
      <li><p class="small">ne pas avoir de stock dans un État membre de l'UE</p></li>
      <li><p class="small">ne pas faire de dropshipping</p></li>
      <li>
        <p class="small">
          faire moins de 10k € de CA de ventes BtoC en UE sur l'année civile précédente ou l'année en cours
        </p>
      </li>
      <li><p class="small">ne pas souhaiter souscrire dès maintenant à l'option guichet unique (OSS).</p></li>
    </ul>
    <p class="small mb-8">
      <b>> Vous appliquez donc la TVA française, qui sera déclarée via votre CA3/CA12.</b>
    </p>
    <p class="small mb-8">
      <b>> Si vous êtes non assujetti à la TVA, vous restez exonéré de TVA.</b>
    </p>
    <p class="small mb-8 color-error">⚠️ IMPORTANT ⚠️</p>
    <p class="small mb-8">
      Si l'une de ces conditions venait à changer, prenez rapidement RDV avec un comptable pour vous mettre à jour dans
      vos obligations fiscales.
    </p>
    <p class="small mb-8">
      <b>
        > En effet, vous devrez alors appliquer la TVA en vigueur dans l'État membre de vos clients et la déclarer au
        guichet unique OSS-UE ou à l'IOSS.
      </b>
    </p>
    <p class="small mb-8">
      Vous devrez vous inscrire sur impots.gouv.fr le trimestre précédant la première vente concernée par un changement
      de situation, sous peine de devoir vous immatriculer dans tous les États membres où vous vendez.
    </p>
  </dougs-panel-info>
  <dougs-panel-info type="default" class="mt-8" *ngIf="salesViaPlatformConfiguration.hasOss">
    <p class="small mb-8">
      Vous êtes concerné par les nouvelles règles de TVA pour la vente intra-communautaire, applicables à partir du 1er
      juillet 2021.
    </p>
    <dougs-divider class="mb-8"></dougs-divider>
    <p class="small mb-8">
      <b>
        > Vous devez appliquer la TVA en vigueur dans l'État membre de vos clients et la déclarer via le guichet unique
        OSS-EU.
      </b>
    </p>
    <p class="small mb-8">Cela concerne :</p>
    <ul class="mb-8">
      <li><p class="small">Toutes vos ventes de prestations électroniques à des particuliers en UE.</p></li>
      <li>
        <p class="small">
          Toutes vos ventes de marchandises, depuis un stock en France ou en UE, à des particuliers en UE.
        </p>
      </li>
    </ul>
    <p class="small mb-8">
      <b>
        <u>Concernant les ventes en France</u>
        , la TVA sera déclarée sur la CA3/CA12.
      </b>
    </p>
    <p class="small mb-8">
      La déclaration OSS-UE est trimestrielle, vous la retrouverez dans votre module Déclaration.
    </p>
    <p class="small mb-8">
      <b><u>Attention ! Concernant les ventes depuis un stock en UE :</u></b>
    </p>
    <p class="small mb-8">
      <b>
        Si vous vendez à un consommateur se situant dans le même pays que votre stock, vous devrez déclarer et reverser
        la TVA dans la déclaration nationale du pays du stock.
      </b>
    </p>
    <p class="small mb-8">
      Dans ce cas, vous êtes dans l'obligation d'obtenir un numéro d'identification à la TVA dans le pays du stock.
    </p>
    <p class="small mb-8">
      Dougs ne pourra pas vous fournir les déclarations nationales des États membres de l'UE. Nous vous invitons, si ce
      n'est déjà fait, à vous rapprocher d'un mandataire spécialisé pour vous accompagner dans cette démarche.
    </p>
  </dougs-panel-info>
  <dougs-panel-info type="default" class="mt-8" *ngIf="isDropShipper">
    <p class="small mb-8">
      Vous êtes concerné par les nouvelles règles de TVA pour vente à distance de biens importés (dropshipping),
      applicables à partir du 1er juillet 2021.
    </p>
    <dougs-divider class="mb-8"></dougs-divider>
    <p class="small mb-8">
      <b>
        > Vous devez appliquer la TVA en vigueur dans l'État membre de vos clients pour vos ventes à distance de biens
        importés (dropshipping).
      </b>
    </p>
    <p class="small mb-8">
      L'encaissement, la déclaration et le paiement de la TVA perçue sur ces ventes dépendent de la situation :
    </p>
    <p class="small mb-8">
      <b>
        <u>
          Pour le dropshipping de colis d'une valeur inférieure à 150 € effectué depuis votre propre site internet :
        </u>
      </b>
    </p>
    <ul class="mb-8">
      <li><p class="small">Vous appliquez la TVA du pays membre de consommation</p></li>
      <li><p class="small">Vous facturez la TVA</p></li>
      <li><p class="small">Vous déclarez et reversez la TVA sur le guichet unique IOSS sur impots.gouv.fr</p></li>
    </ul>
    <p class="small mb-8">
      La déclaration IOSS est mensuelle, vous la retrouverez dans le module Déclaration de Dougs.
    </p>
    <p class="small mb-8">
      <b>
        <u>
          Pour le dropshipping de colis d'une valeur inférieure à 150 € effectué depuis une market place (ex : Amazon) :
        </u>
      </b>
    </p>
    <p class="small mb-8">
      La TVA est directement facturée et reversée par la plateforme ou la market place par laquelle vous passez pour
      commercialiser vos produits.
    </p>
    <p class="small mb-8">Vous êtes donc déchargé de la collecte.</p>
    <p class="small mb-8">
      <b>
        <u>
          Pour le dropshipping de colis d'une valeur supérieure à 150 € effectué depuis une market place (ex : Amazon)
          ou depuis votre propre site internet :
        </u>
      </b>
    </p>
    <ul class="mb-8">
      <li><p class="small">Vous appliquez la TVA en vigueur dans l'État membre de vos clients</p></li>
      <li><p class="small">Vous facturez la TVA</p></li>
      <li>
        <p class="small">
          Vous déclarez et reversez la TVA dans l'État membre de vos clients.
          <b>
            Vous devrez donc vous immatriculer à la TVA dans chacun des États membres dans lesquels se situent vos
            clients particuliers.
          </b>
        </p>
      </li>
    </ul>
  </dougs-panel-info>
  <dougs-panel-info type="default" class="mt-8" *ngIf="salesViaPlatformConfiguration.individualEuropeanSales">
    <p class="small mb-8">
      <b>Comment cela se passe dans Dougs ?</b>
    </p>
    <p class="small mb-8">
      Nos équipes ont mis en place les déclarations OSS-UE et IOSS, ainsi que toutes les catégories nécessaires, pour
      une bonne tenue de vos comptes quelle que soit votre situation.
    </p>
    <p class="small mb-8">Vous verrez apparaitre vos formulaires iOSS et/ou OSS dans Dougs.fr</p>
    <p class="small mb-8">
      <b>Des questions ?</b>
    </p>
    <p class="small mb-8">Retrouvez toutes ces informations dans vos paramètres Dougs !</p>
    <p class="small mb-8">Si vous avez la moindre question, nous vous invitons à visionner à nos Webinars dédiés :</p>
    <ul class="mb-8">
      <li>
        <p class="small">
          <a
            rel="noopener"
            href="https://app.livestorm.co/dougs/e-commercant-comment-optimiser-votre-compta-et-fiscalite-tout-ce-que-vous-devez-savoir?utm_source=dougs-parametre"
            target="_blank"
          >
            E-Commerce : comment tenir ta compta et ta TVA ? Tout ce que tu dois savoir !
          </a>
        </p>
      </li>
    </ul>
    <p class="small mb-8">N'hésitez pas non plus à lire nos contenus sur le sujet :</p>
    <ul class="mb-8">
      <li>
        <p class="small">
          <a
            rel="noopener"
            href="https://www.dougs.fr/blog/e-commerce-en-union-europeenne-le-point-sur-le-guichet-unique-oss-ioss-de-tva/"
            target="_blank"
          >
            E-commerce en Union européenne : le point sur le guichet unique OSS-IOSS de TVA
          </a>
        </p>
      </li>
      <li>
        <p class="small">
          <a
            rel="noopener"
            href="https://www.dougs.fr/blog/e-commerce-et-tva-ce-qui-change-avec-le-nouveau-regime-des-ventes-a-distance/"
            target="_blank"
          >
            E-commerce et TVA : ce qui change avec le nouveau régime des ventes à distance
          </a>
        </p>
      </li>
      <li>
        <p class="small">
          <a
            rel="noopener"
            href="https://www.dougs.fr/blog/dropshipping-et-tva-ce-qui-change-avec-le-nouveau-regime-des-ventes-a-distance-de-biens-importes-en-union-europeenne/"
            target="_blank"
          >
            Dropshipping et TVA : ce qui change avec le nouveau régime des ventes à distance de biens importés en Union
            européenne
          </a>
        </p>
      </li>
    </ul>
    <p class="small mb-8">Nos équipes restent évidemment disponibles par tchat ou téléphone pour vous accompagner.</p>
    <p class="small mb-8">Merci à vous !</p>
  </dougs-panel-info>
</div>
<div dougsModalFooter>
  <dougs-button dougsModalClose>OK</dougs-button>
</div>
