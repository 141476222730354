<div [formGroup]="ecommerceSalesChannelFormService.formGroup">
  <dougs-panel-info
    type="warning"
    *ngIf="ecommerceSalesChannelFormService.alreadyHaveOneAmazonEu$ | async"
    class="mb-24"
  >
    Attention, vous avez déjà un canal de vente Amazon Europe actif. Il inclut tout le réseau de distribution européen
    disponible sur votre compte Amazon Europe (France, Allemagne, Royaume-Uni, etc...). Vous n'avez pas besoin de créer
    d'autres canaux Amazon Europe, sauf si vous avez d’autres boutiques Amazon.
  </dougs-panel-info>
  <dougs-form-field
    *ngIf="ecommerceSalesChannelFormService.formGroup.controls.category.value === SalesChannelCategory.MARKETPLACE"
  >
    <label dougsFormFieldLabel>
      Marketplace
      <i (click)="ecommerceSalesChannelFormService.openMissingPlatformModal()" class="fal fa-question-circle"></i>
    </label>
    <dougs-select
      [searchable]="true"
      dougsFormFieldControl
      formControlName="type"
      maxHeight="300"
      placeholder="Sélectionnez une marketplace"
    >
      <ng-container *ngIf="creating; else updating">
        <dougs-select-option
          *ngFor="
            let marketPlace of salesChannelStateService.marketPlacesConfigurationListWithoutDisabled$ | async;
            let index = index;
            trackBy: trackByValue
          "
          value="{{ marketPlace.value }}"
        >
          {{ marketPlace.label }}
        </dougs-select-option>
      </ng-container>
      <ng-template #updating>
        <dougs-select-option
          *ngFor="
            let marketPlace of salesChannelStateService.marketPlacesConfigurationListWithDisabled$ | async;
            let index = index;
            trackBy: trackByValue
          "
          value="{{ marketPlace.value }}"
        >
          {{ marketPlace.label }}
        </dougs-select-option>
      </ng-template>
    </dougs-select>
    <span
      *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.type)"
      dougsFormFieldError
    >
      <span *ngIf="ecommerceSalesChannelFormService.formGroup.controls.type.hasError('required')"
        >Ce champ est requis</span
      >
    </span>
  </dougs-form-field>

  <dougs-form-field
    *ngIf="ecommerceSalesChannelFormService.formGroup.controls.category.value === SalesChannelCategory.WEBSITE"
  >
    <label dougsFormFieldLabel>
      Vous avez créé votre site en ligne avec
      <i (click)="ecommerceSalesChannelFormService.openMissingPlatformModal()" class="fal fa-question-circle"></i>
    </label>
    <dougs-select
      dougsFormFieldControl
      [searchable]="true"
      formControlName="type"
      maxHeight="300"
      placeholder="Sélectionnez l'outil que vous utilisez"
    >
      <dougs-select-option
        *ngFor="
          let ecommerceWebsiteSoftware of salesChannelStateService.ecommerceWebsiteSoftwareConfigurationList$ | async;
          let index = index;
          trackBy: trackByValue
        "
        value="{{ ecommerceWebsiteSoftware.value }}"
      >
        {{ ecommerceWebsiteSoftware.label }}
      </dougs-select-option>
    </dougs-select>
    <span
      *ngIf="formService.isControlInvalid(ecommerceSalesChannelFormService.formGroup.controls.type)"
      dougsFormFieldError
    >
      <span *ngIf="ecommerceSalesChannelFormService.formGroup.controls.type.hasError('required')"
        >Ce champ est requis</span
      >
    </span>
  </dougs-form-field>
</div>
