<ng-container [formGroup]="ecommerceFormService.formGroup">
  <ng-container *ngIf="ecommerceFormService.individualServicesEuSalesValuesChanges$ | async"></ng-container>
  <ng-container formGroupName="salesViaPlatformConfiguration">
    <div class="form-layout grid mb-16">
      <label dougsFormFieldLabel class="question">
        Faites-vous de la vente de services électroniques
        <i (click)="ecommerceFormService.openIndividualServicesInfoModal()" class="fal fa-question-circle"></i> à des
        particuliers vers un autre pays de l'Union Européenne que la France&nbsp;?
      </label>
      <dougs-radio-group formControlName="individualServicesEuSales" appearance="yesNo">
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [value]="false" [noDesign]="true">Non</dougs-radio>
      </dougs-radio-group>
    </div>
  </ng-container>
</ng-container>
