import { Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import {
  AuthorizeSalesChannelResponse,
  EcommerceShopSynchronizeResult,
  EcommerceShopSyncModalComponentData,
  EcommerceShopSyncMode,
  SalesChannel,
} from '@dougs/ecommerce/dto';
import {
  ecommerceShopSyncConfig,
  EcommerceShopSyncStateService,
  SalesChannelStateService,
} from '@dougs/ecommerce/shared';

interface EcommerceShopSyncForm {
  shopUrl: FormControl<string>;
  apiKeys?: FormGroup<EcommerceShopSyncApiKeysForm>;
}

interface EcommerceShopSyncApiKeysForm {
  publicKey: FormControl<string>;
  secretKey: FormControl<string>;
}

@Injectable()
export class EcommerceShopSyncModalComponentService {
  private readonly isLoading: WritableSignal<boolean> = signal<boolean>(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  form: FormGroup<EcommerceShopSyncForm> = new FormGroup<EcommerceShopSyncForm>({
    shopUrl: new FormControl<string>('', { nonNullable: true }),
  });
  syncMode: EcommerceShopSyncMode = ecommerceShopSyncConfig[this.data.salesChannel.type].syncMode;
  shopTypeLabel: string = ecommerceShopSyncConfig[this.data.salesChannel.type].shopTypeLabel;
  metricsBeforeClosed$: Observable<void> = this.modalRef.beforeClosed$.pipe(
    filter((event) => event.type === 'close' && !event.data),
    map(() => this.sendMetrics('e-commerce Sales Channel Synchronization Request modal closed')),
  );

  constructor(
    @Inject(MODAL_DATA) public data: EcommerceShopSyncModalComponentData,
    @Inject(Window) private readonly window: Window,
    private readonly modalRef: ModalRef,
    private readonly salesChannelStateService: SalesChannelStateService,
    private readonly ecommerceShopSyncStateService: EcommerceShopSyncStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
  ) {
    this.setApiKeysForm();
    this.form.patchValue({ shopUrl: this.data.salesChannel.shopUrl || '' });
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading.set(true);

      this.sendMetrics('e-commerce Sales Channel Synchronization Requested');

      if (this.data.salesChannel.shopUrl !== this.form.value.shopUrl) {
        await this.updateSalesChannel();
      }

      const syncResult: EcommerceShopSynchronizeResult | null = await this.synchronizeShop();

      this.isLoading.set(false);

      if (syncResult !== null) {
        if (syncResult?.authUrl) {
          this.window.open(decodeURI(syncResult.authUrl), '_blank', 'noopener');
        }
        this.modalRef.close(true);
      }
    }
  }

  private async synchronizeShop(): Promise<EcommerceShopSynchronizeResult | null> {
    const connectedSalesChannel: SalesChannel | null = await this.salesChannelStateService.connectSalesChannel(
      this.data.salesChannel,
      {
        shop: this.form.value.shopUrl ?? '',
        publicKey: this.form.value.apiKeys?.publicKey,
        secretKey: this.form.value.apiKeys?.secretKey,
      },
    );

    if (!connectedSalesChannel) {
      return null;
    }

    if (this.syncMode === EcommerceShopSyncMode.URL) {
      const authorizationResponse: AuthorizeSalesChannelResponse =
        await this.salesChannelStateService.authorizeSalesChannel(connectedSalesChannel);
      return { authUrl: authorizationResponse.authUrl };
    }

    return { authUrl: null };
  }

  private async updateSalesChannel(): Promise<void> {
    await this.salesChannelStateService.updateSalesChannel(this.companyStateService.activeCompany, {
      ...this.data.salesChannel,
      id: this.data.salesChannel.id.toString(),
      paymentsProcessorsWithDate:
        this.data.salesChannel.paymentsProcessorsWithDate?.map((paymentProcessor) => {
          return {
            id: paymentProcessor.id,
            paymentsProcessorId: paymentProcessor.paymentsProcessor.id,
            endDate: paymentProcessor.endDate,
          };
        }) || [],
      shopUrl: this.form.value.shopUrl,
    });
  }

  private setApiKeysForm(): void {
    if (this.syncMode === EcommerceShopSyncMode.KEYS) {
      this.form.addControl(
        'apiKeys',
        new FormGroup<EcommerceShopSyncApiKeysForm>({
          publicKey: new FormControl<string>('', {
            validators: [Validators.required, Validators.minLength(20), Validators.maxLength(128)],
            nonNullable: true,
          }),
          secretKey: new FormControl<string>('', {
            validators: [Validators.required, Validators.minLength(20)],
            nonNullable: true,
          }),
        }),
      );
    }
  }

  private sendMetrics(event: string): void {
    this.metricsService.pushMixpanelEvent(event, {
      'Sale Channel Category': this.data.salesChannel.category,
      'Sale Channel Type': this.data.salesChannel.type,
      'CTA Location': this.data.origin,
    });
  }
}
