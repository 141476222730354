<form [formGroup]="ecommerceFormService.formGroup" *ngIf="ecommerceFormService.formGroup" (ngSubmit)="onSubmit()">
  <div class="settings-card__content">
    <div>
      <div class="settings-card__sticky-column-title">
        <h5>Questionnaire fiscal</h5>
        <p class="subtitle mt-8">
          La vente en ligne implique certaines obligations fiscales. Ce questionnaire permet de savoir lesquelles vous
          concernent.
        </p>
        <ng-container
          *ngIf="
            (userStateService.loggedInUser$ | async).isAccountantOrAdmin ||
            (userStateService.loggedInUser$ | async).flags.includes('role:sales')
          "
        >
          <ng-container *ngIf="ecommerceFormService.lockedValuesChanges$ | async"></ng-container>
          <div class="mt-16" formGroupName="salesViaPlatformConfiguration">
            <dougs-checkbox
              formControlName="locked"
              appearance="toggle"
              [showYesNo]="true"
              [isAdmin]="true"
              yesText="Questionnaire verrouillé"
              noText="Questionnaire déverrouillé"
            ></dougs-checkbox>
          </div>
          <ng-container *ngIf="ecommerceFormService.ecommerceExtensionDeactivatedValuesChanges$ | async"></ng-container>
          <div class="mt-8" formGroupName="salesViaPlatformConfiguration">
            <dougs-checkbox
              formControlName="ecommerceExtensionDeactivated"
              appearance="toggle"
              [showYesNo]="true"
              [isAdmin]="true"
              yesText="Extension e-commerce facturable"
              noText="Extension ecommerce non facturable"
            ></dougs-checkbox>
          </div>
        </ng-container>
        <dougs-panel-info class="mt-16 background-gray">
          <div class="ecommerce-panel-info">
            <i class="fal fa-comments-alt mt-4 color-primary-700"></i>
            <span>
              Votre situation change ? Vous avez des questions ?
              <a href="#" (click)="ecommerceComponentService.openIntercom($event)">Contactez-nous</a>
            </span>
          </div>
        </dougs-panel-info>
      </div>
    </div>
    <div>
      <dougs-ecommerce-form [company]="company"></dougs-ecommerce-form>
      <div class="form-layout" *ngIf="company.salesViaPlatformConfiguration.locked" @slideUpDown>
        <div>
          <dougs-divider class="mb-16"></dougs-divider>
          <dougs-button
            class="mb-16"
            [fullWidth]="true"
            color="primary-light"
            (click)="openSalesViaPlatformInfoModal()"
          >
            <i class="fal fa-list color-primary mr-4"></i>
            Voir vos obligations fiscales
          </dougs-button>
        </div>
      </div>
      <div
        *ngIf="ecommerceFormService.showValidateFormButton$ | async"
        class="settings-card__button-container py-16 px-32"
        @slideUpDown
      >
        <dougs-button [disabled]="!canValidateSettings" type="submit">Valider mon questionnaire</dougs-button>
      </div>
    </div>
  </div>
  <dougs-divider
    *ngIf="company.accountingConfiguration.hasOss || company.accountingConfiguration.hasIoss"
  ></dougs-divider>
  <div
    class="settings-card__content"
    *ngIf="company.accountingConfiguration.hasOss || company.accountingConfiguration.hasIoss"
  >
    <div>
      <div class="settings-card__sticky-column-title">
        <h5 class="mb-8">Inscription au guichet unique</h5>
        <ng-container *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin">
          <ng-container *ngIf="ecommerceFormService.lockedDateValuesChanges$ | async"></ng-container>
          <div formGroupName="salesViaPlatformConfiguration">
            <dougs-checkbox
              formControlName="dateLocked"
              appearance="toggle"
              [showYesNo]="true"
              [isAdmin]="true"
              yesText="Inscription verrouillée"
              noText="Inscription déverrouillée"
            ></dougs-checkbox>
          </div>
        </ng-container>
        <dougs-panel-info class="mt-16">
          <div class="ecommerce-panel-info">
            <i class="fal fa-lightbulb-on color-primary mt-4"></i>
            <div>
              <p class="bold small">
                Besoin d’aide pour votre inscription à l'{{ ecommerceFormService.ossIossInfoText$ | async }} ?
              </p>
              Laissez-vous guider dans
              <ng-container *ngIf="company.accountingConfiguration.hasOss && company.accountingConfiguration.hasIoss">
                ces
                <a
                  rel="noopener"
                  class="bold"
                  href="https://aide.dougs.fr/fr/articles/5341701-comment-adherer-au-service-guichet-unique-de-tva-ue-one-stop-shop"
                  target="_blank"
                  (click)="ecommerceComponentService.onResourceLinkClick()"
                  >tutoriel OSS</a
                >,
                <a
                  rel="noopener"
                  class="bold"
                  href="https://aide.dougs.fr/fr/articles/5387836-comment-adherer-au-service-guichet-unique-de-tva-ue-import-one-stop-shop"
                  target="_blank"
                  (click)="ecommerceComponentService.onResourceLinkClick()"
                  >tutoriel IOSS</a
                >.
              </ng-container>
              <ng-container *ngIf="!company.accountingConfiguration.hasOss && company.accountingConfiguration.hasIoss">
                ce
                <a
                  rel="noopener"
                  class="bold"
                  href="https://aide.dougs.fr/fr/articles/5387836-comment-adherer-au-service-guichet-unique-de-tva-ue-import-one-stop-shop"
                  target="_blank"
                  (click)="ecommerceComponentService.onResourceLinkClick()"
                  >tutoriel IOSS</a
                >.
              </ng-container>
              <ng-container *ngIf="company.accountingConfiguration.hasOss && !company.accountingConfiguration.hasIoss">
                ce
                <a
                  rel="noopener"
                  class="bold"
                  href="https://aide.dougs.fr/fr/articles/5341701-comment-adherer-au-service-guichet-unique-de-tva-ue-one-stop-shop"
                  target="_blank"
                  (click)="ecommerceComponentService.onResourceLinkClick()"
                  >tutoriel OSS</a
                >.
              </ng-container>
            </div>
          </div>
        </dougs-panel-info>
      </div>
    </div>
    <div>
      <dougs-ecommerce-inscription [company]="company"></dougs-ecommerce-inscription>
      <div
        *ngIf="ecommerceFormService.showValidateIOSSButton$ | async"
        class="settings-card__button-container no-decoration not-sticky py-16 px-32"
        @slideUpDown
      >
        <dougs-button [disabled]="!(ecommerceFormService.enableValidateLockDate$ | async)" type="submit">
          Enregistrer
        </dougs-button>
      </div>
    </div>
  </div>
</form>
